import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NewQuote from '../views/NewQuote'
import Property from '../views/Property'
import Rideshare from '../views/Rideshare'
import Review from '../views/Review'
import NotFound from '../views/NotFound'
import Login from '../views/Login'
import Redirector from '../views/Redirector'
import withTracker from '../../utils/withTracker'
import IsAuthenticated from '../../components/hooks/IsAuthenticated'
import ProtectedRoute from '../../components/hooks/ProtectedRoute'
const Routes = () => (
    <Switch>
        <Route path="/new/:flow" exact component={withTracker(NewQuote)} />
        <Route path="/property" component={withTracker(Property)} />
        <Route path="/rideshare" component={withTracker(Rideshare)} />
        <Route path="/review" exact component={withTracker(Review)} />
        <Route path="/login/:flow" component={withTracker(Login)} />
        <IsAuthenticated>
            <ProtectedRoute path="/" component={withTracker(Redirector)} />
        </IsAuthenticated>
        <Route component={withTracker(NotFound)} />
    </Switch>
)

export default Routes
