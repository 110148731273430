import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Submit, Select, Text } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    excess: Yup.number().required('Required')
})

const Excess = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Excess</Heading>
            <FormManager
                useSession
                initialValues={{
                    excess: 1000
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts({
                        ...values,
                        excess: parseInt(values.excess)
                    })
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Select
                            name="excess"
                            label="Excess Amount"
                            required
                            choices={{
                                1000: '$1,000',
                                1500: '$1,500',
                                2000: '$2,000'
                            }}
                        />
                        <Text>
                            The amount you will contribute toward each claim.
                            Increasing your excess will typically reduce your
                            premium.
                        </Text>
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Excess
