import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { Heading, Alert, Date, Select, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    dob: Yup.string().required('Required'),
    licenseLength: Yup.string()
        .required('Required')
        .oneOf(
            ['1', '2', '3', '4', '5'],
            "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
        )
})

const Driver = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Driver Details</Heading>
            <FormManager
                useSession
                initialValues={{
                    dob: '',
                    licenseLength: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts({
                        ...values,
                        licenseLength: parseInt(values.licenseLength)
                    })
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Date
                            name="dob"
                            label="Date of birth"
                            required
                            allowFuture={false}
                            allowPast
                            startDate={DateTime.local()
                                .minus({ years: 100 })
                                .toISODate()}
                            endDate={DateTime.local()
                                .minus({ years: 21 })
                                .toISODate()}
                            defaultMonth={DateTime.local()
                                .minus({ years: 21 })
                                .toISODate()}
                        />
                        <Select
                            name="licenseLength"
                            label="Years licensed (excluding learners)"
                            placeholder="Please select the number of years you have been driving after receiving your license"
                            required
                            choices={{
                                0: 'Less than 1 year',
                                1: '1 year',
                                2: '2 years',
                                3: '3 years',
                                4: '4 years',
                                5: '5 or more'
                            }}
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Driver
