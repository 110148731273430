import React from 'react'
import { connect } from 'react-redux'
import { Heading, Button, Text, Box, Link } from '@sharecover-co/ui'
import CheckoutLayout from '../layouts/CheckoutLayout'
import BaseConditions from './tandcs/BaseConditions'

const Review = ({ quote, loading, error }) => (
    <CheckoutLayout title="Quote" progress={50} loading={loading}>
        {error && <Heading sx={{ fontSize: 2 }}>{error}</Heading>}
        {quote && (
            <>
                <Heading sx={{ fontSize: 2 }}>Review</Heading>
                <Text>Would you like to purchase this policy?</Text>
                <Box sx={{ my: 3 }}>
                    <Link
                        href={`${process.env.REACT_APP_PURCHASE_URL}/quote/${quote.id}?accessKey=${quote.accessKey}`}
                        sx={{ variant: 'links.button' }}
                    >
                        Continue
                    </Link>
                </Box>
            </>
        )}
        <Box sx={{ my: 3 }}>
            <Button onClick={() => window.history.go(-1)} variant="outline">
                Back
            </Button>
        </Box>
        <BaseConditions />
    </CheckoutLayout>
)

const mapStateToProps = (state) => ({
    ...state.quote
})

export default connect(mapStateToProps)(Review)
