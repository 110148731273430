import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { vehicleLookup } from '../../../actions/vehicle'

const getMakes = ({ year }) =>
    vehicleLookup({
        lookup: 'make',
        year
    })

const VehicleMake = (props) => {
    const [makes, setMakes] = useState([])
    const [field] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (values.year) {
            getMakes(values).then((data) => {
                setMakes(data)

                // reset value as its not in our new set
                if (!data.includes(values[field.name])) {
                    setFieldValue(field.name, '')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.year])

    if (!values.year) {
        return null
    }

    const choices = Object.fromEntries(makes.map((m) => [m, m]))

    return <Select {...field} {...props} choices={choices} />
}

VehicleMake.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string
}

VehicleMake.defaultProps = {
    name: 'make',
    label: 'Make',
    required: true,
    placeholder: 'Please select a make...'
}

export default VehicleMake
