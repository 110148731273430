import { combineReducers } from 'redux'
import session from './session'
import product from './product'
import quote from './quote'
import initialHomeCoverValues from './initialHomeCoverValues'

export default combineReducers({
    session,
    product,
    quote,
    initialHomeCoverValues
})
