import { connect } from 'react-redux'
import { Text, Radio, Link, Submit, Button } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const TinyHome = (props) => {
    let condition =
        get(props, 'data.address.formatted', null) &&
        get(props, 'data.isStrata', null) &&
        get(props, 'data.hasPlatform', null) === 'true' &&
        get(props, 'data.propertyType', null) &&
        get(props, 'data.propertyType', null) !== 'other' &&
        get(props, 'data.propertyType', null) !== 'caravan' &&
        get(props, 'data.propertyType', null) !== 'temporary' &&
        get(props, 'data.propertyType', null) !== 'retirement' &&
        (get(props, 'data.policy', null) ||
            (get(props, 'data.wellMaintained', null) &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID)) &&
        get(props, 'data.endDate', null) &&
        get(props, 'data.numberOfGuests', null) &&
        get(props, 'data.atProperty', null) &&
        get(props, 'data.excess', null)

    return condition ? (
        <>
            <Radio
                name="purchase"
                id="purchase"
                label="Would you like to purchase this policy?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            {props.data.purchase === 'true' &&
                props.quote &&
                !props.loading &&
                props.product.id ===
                    process.env.REACT_APP_SHORT_HOMECOVER_PRODUCT_ID && (
                    <Submit id="submit" label="Submit" />
                )}
            {props.data.purchase === 'true' &&
                props.quote &&
                !props.loading &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <>
                        <dialog id="submit-confirmation">
                            <Text>
                                ShareCover Short Stay and Pay-per night
                                insurance only covers Your Home against certain
                                guest related risks and liability during the
                                short-term rental of Your Home, as set out in
                                the PDS.
                            </Text>
                            <Text>
                                It <b>does not</b> provide cover for loss or
                                damage from natural perils, weather events, or
                                other events that are not directly caused by a
                                Guest or their visitors, such as risks typically
                                included in a Home Buildings and Contents
                                Insurance policy. If you would like cover for
                                these events it will need to be obtained
                                separately.
                            </Text>
                            <p>
                                <Submit label="I understand and am happy to proceed" />
                            </p>
                            <p>
                                <Link
                                    href={`#`}
                                    sx={{ variant: 'links.buttonOutline' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        document
                                            .querySelector(
                                                '#submit-confirmation'
                                            )
                                            .close()
                                    }}
                                >
                                    Abandon the transaction
                                </Link>
                            </p>
                        </dialog>
                        <Button
                            id="submit-button"
                            sx={{ mt: 3 }}
                            onClick={(e) => {
                                e.preventDefault()
                                document
                                    .querySelector('#submit-confirmation')
                                    .showModal()
                            }}
                        >
                            Submit
                        </Button>
                    </>
                )}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    quote: get(state, 'quote.quote', null),
    product: get(state, 'product.product', {}),
    loading: get(state, 'quote.loading', false)
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(TinyHome)
