import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from '@aws-amplify/core'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider } from 'react-modal-hook'
import ScrollToTop from 'react-router-scroll-top'
import { ThemeProvider, globalStyles, Global } from '@sharecover-co/ui'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './components/root/App'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import theme from './theme'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        cookieStorage: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN
        }
    },
    API: {
        graphql_endpoint_iam_region: process.env.REACT_APP_APPSYNC_REGION,
        endpoints: [
            {
                name: 'API',
                endpoint: process.env.REACT_APP_API_ENDPOINT,
                region: process.env.REACT_APP_API_REGION
            }
        ]
    }
})

let myAppConfig = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
    aws_appsync_authenticationType: 'AWS_IAM'
}

Amplify.configure(myAppConfig)

Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [process.env.REACT_APP_API_ENDPOINT]
        })
    ],
    tracesSampleRate: 0.5
})

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
}

if (process.env.REACT_APP_GOOGLE_GTM_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_GTM_ID
    }

    TagManager.initialize(tagManagerArgs)
}

const { store, persistor } = configureStore()

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ModalProvider>
                    <Router>
                        <ScrollToTop>
                            <App />
                        </ScrollToTop>
                    </Router>
                </ModalProvider>
            </PersistGate>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
