/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addClaim = /* GraphQL */ `
  mutation AddClaim($input: ClaimInput!) {
    addClaim(input: $input) {
      createdAt
      data
      documents {
        key
        name
        url
      }
      id
      lossCodes
      policy {
        cancelledAt
        createdAt
        data
        description
        endDate
        endTime
        id
        paymentMethod {
          cardType
          createdAt
          expirationMonth
          expirationYear
          gateway
          id
          last4
          token
          userId
        }
        paymentMethodId
        paymentSchedule
        payments {
          nextToken
        }
        premium {
          price
          tax
          total
        }
        product {
          claimFlowId
          createdAt
          description
          id
          name
          policyDescriptionPath
          policyIdPrefix
          premiumPriceId
          quoteFlowId
          unitPriceId
          updatedAt
        }
        productId
        quoteId
        startDate
        startTime
        unit {
          price
          tax
          total
        }
        updatedAt
        userId
      }
      policyId
      status
      updatedAt
      user {
        address {
          city
          country
          formatted
          isManualAddress
          placeId
          postcode
          region
          streetAddress
          suiteAddress
          town
        }
        createdAt
        dob
        email
        firstName
        id
        lastName
        phone
        updatedAt
      }
      userId
    }
  }
`;
export const addPaymentMethod = /* GraphQL */ `
  mutation AddPaymentMethod($input: PaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      cardType
      createdAt
      expirationMonth
      expirationYear
      gateway
      id
      last4
      token
      userId
    }
  }
`;
export const addPolicy = /* GraphQL */ `
  mutation AddPolicy($input: PolicyInput!) {
    addPolicy(input: $input) {
      cancelledAt
      createdAt
      data
      description
      endDate
      endTime
      id
      paymentMethod {
        cardType
        createdAt
        expirationMonth
        expirationYear
        gateway
        id
        last4
        token
        userId
      }
      paymentMethodId
      paymentSchedule
      payments {
        items {
          chargeId
          chargedAt
          createdAt
          dueAt
          failedAt
          policyId
          price
          scheduledDate
          scheduledTime
          tax
          total
          updatedAt
        }
        nextToken
      }
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      productId
      quoteId
      startDate
      startTime
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      updatedAt
      userId
    }
  }
`;
export const addPolicyByQuote = /* GraphQL */ `
  mutation AddPolicyByQuote($input: PolicyByQuoteInput!) {
    addPolicyByQuote(input: $input) {
      cancelledAt
      createdAt
      data
      description
      endDate
      endTime
      id
      paymentMethod {
        cardType
        createdAt
        expirationMonth
        expirationYear
        gateway
        id
        last4
        token
        userId
      }
      paymentMethodId
      paymentSchedule
      payments {
        items {
          chargeId
          chargedAt
          createdAt
          dueAt
          failedAt
          policyId
          price
          scheduledDate
          scheduledTime
          tax
          total
          updatedAt
        }
        nextToken
      }
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      productId
      quoteId
      startDate
      startTime
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      updatedAt
      userId
    }
  }
`;
export const addProfile = /* GraphQL */ `
  mutation AddProfile($input: AddProfileInput!) {
    addProfile(input: $input) {
      address {
        city
        country
        formatted
        isManualAddress
        placeId
        postcode
        region
        streetAddress
        suiteAddress
        town
      }
      createdAt
      dob
      email
      firstName
      id
      lastName
      phone
      updatedAt
    }
  }
`;
export const addUsage = /* GraphQL */ `
  mutation AddUsage($input: UsageInput!) {
    addUsage(input: $input) {
      createdAt
      endDate
      id
      meta
      policy {
        cancelledAt
        createdAt
        data
        description
        endDate
        endTime
        id
        paymentMethod {
          cardType
          createdAt
          expirationMonth
          expirationYear
          gateway
          id
          last4
          token
          userId
        }
        paymentMethodId
        paymentSchedule
        payments {
          nextToken
        }
        premium {
          price
          tax
          total
        }
        product {
          claimFlowId
          createdAt
          description
          id
          name
          policyDescriptionPath
          policyIdPrefix
          premiumPriceId
          quoteFlowId
          unitPriceId
          updatedAt
        }
        productId
        quoteId
        startDate
        startTime
        unit {
          price
          tax
          total
        }
        updatedAt
        userId
      }
      policyId
      price {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      startDate
    }
  }
`;
export const cancelPolicy = /* GraphQL */ `
  mutation CancelPolicy($id: ID!) {
    cancelPolicy(id: $id) {
      cancelledAt
      createdAt
      data
      description
      endDate
      endTime
      id
      paymentMethod {
        cardType
        createdAt
        expirationMonth
        expirationYear
        gateway
        id
        last4
        token
        userId
      }
      paymentMethodId
      paymentSchedule
      payments {
        items {
          chargeId
          chargedAt
          createdAt
          dueAt
          failedAt
          policyId
          price
          scheduledDate
          scheduledTime
          tax
          total
          updatedAt
        }
        nextToken
      }
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      productId
      quoteId
      startDate
      startTime
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      updatedAt
      userId
    }
  }
`;
export const quote = /* GraphQL */ `
  mutation Quote($input: QuoteInput!) {
    quote(input: $input) {
      accessKey
      createdAt
      data
      endDate
      endTime
      id
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      startDate
      startTime
      ttl
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      userId
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      address {
        city
        country
        formatted
        isManualAddress
        placeId
        postcode
        region
        streetAddress
        suiteAddress
        town
      }
      createdAt
      dob
      email
      firstName
      id
      lastName
      phone
      updatedAt
    }
  }
`;
