export const preparePayload = (data) => {
    // convert empty values to undefined
    const payload = JSON.parse(
        JSON.stringify(data, (key, value) => {
            if (value === '' || value === null || value === undefined) {
                return undefined
            }
            return value
        })
    )

    // convert string bools to real bools
    Object.keys(payload).forEach((k) => {
        if (['true', 'false'].includes(payload[k])) {
            payload[k] = payload[k] === 'true'
        }
    })

    return payload
}
