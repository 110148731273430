import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { vehicleLookup } from '../../../actions/vehicle'

const getMarketValue = ({ year, make, model, variant, series, style }) =>
    vehicleLookup({
        lookup: 'marketValue',
        year,
        make,
        model,
        variant,
        series,
        style
    })

const VehicleMarketValue = ({ vehicle, ...props }) => {
    const [field] = useField(props)
    const { setFieldValue } = useFormikContext()

    useEffect(() => {
        getMarketValue(vehicle).then((data) => {
            if (field.value !== data.marketValue) {
                setFieldValue(field.name, data.marketValue)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Field {...field} {...props} />
}

VehicleMarketValue.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.string
}

VehicleMarketValue.defaultProps = {
    name: 'marketValue',
    label: 'The current market value of your car is:',
    required: true,
    placeholder: '',
    type: 'hidden'
}

export default VehicleMarketValue
