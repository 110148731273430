import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Radio, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    carStatementOptIn: Yup.bool()
        .required('Required')
        .oneOf(
            [true],
            "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
        ),
    termsAgreement: Yup.bool()
        .required('Required')
        .oneOf(
            [true],
            "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
        )
})

const Other = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Terms and conditions</Heading>
            <FormManager
                useSession
                initialValues={{
                    carStatementOptIn: '',
                    termsAgreement: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Radio
                            name="carStatementOptIn"
                            label="Do all of the following statements apply to you?"
                            description={
                                <ul>
                                    <li>
                                        <span>
                                            My car is in a condition that meets
                                            registration requirements in my
                                            State/Territory.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            My car has four wheels only and is
                                            not a motorcycle, moped, scooter, e
                                            scooter, trike or any 'Heavy Class'
                                            of vehicle.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I have held my license for 1 or more
                                            years.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I have not had my licence cancelled
                                            or suspended due to any driving
                                            offences in the past 2 years.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I have not had any insurance
                                            refused, cancelled or voided,
                                            insurance renewal not offered,
                                            special conditions imposed or claims
                                            refused in the last 5 years.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I have not been convicted of a
                                            criminal offence involving fraud or
                                            dishonesty; theft or robbery; arson;
                                            damage or threatened property
                                            damage; injury or threatened injury
                                            to any person in the past 5 years.
                                        </span>
                                    </li>
                                </ul>
                            }
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        <Radio
                            name="termsAgreement"
                            label="Do you agree to all of the following statements?"
                            description={
                                <ul>
                                    <li>
                                        <span>
                                            I agree to the Terms of Use of this
                                            site.
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I acknowledge that I have read the
                                            Product Disclosure Statement
                                            (available in the ‘RideCover
                                            Comprehensive’ section of this page)
                                            and understand that this policy does
                                            not cover some things, as set out in
                                            the PDS. For example, it does not
                                            cover:
                                            <ul>
                                                <li>
                                                    <span>
                                                        my vehicle if it is used
                                                        to transport or deliver
                                                        food or other goods, or
                                                        for any type of courier
                                                        service; and{' '}
                                                    </span>
                                                </li>

                                                <li>
                                                    <span>
                                                        loss or damage caused by
                                                        bushfire, grassfire or
                                                        named cyclone that
                                                        occurs within 48 hours,
                                                        or flood that occurs
                                                        within 14 days, of the
                                                        commencement time of the
                                                        policy. There are
                                                        limited exceptions to
                                                        this – see the PDS for
                                                        details.
                                                    </span>
                                                </li>
                                            </ul>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            I understand this policy does not
                                            cover using my vehicle to transport
                                            or deliver food or other goods, or
                                            is used for any type of courier
                                            service.
                                        </span>
                                    </li>
                                </ul>
                            }
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Other
