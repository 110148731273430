import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { newSession } from '../../actions/session'
import { getInitialHomeCover } from '../../actions/initialHomeCoverValues'
import { Loading } from '@sharecover-co/ui'

const NewQuote = ({
    newSession,
    getInitialHomeCover,
    location: { search },
    match: {
        params: { flow }
    }
}) => {
    const [loading, setloading] = React.useState(true)
    React.useEffect(async () => {
        const params = qs.parse(search, { ignoreQueryPrefix: true })
        await newSession(params)
        getInitialHomeCover()
        setTimeout(() => {
            setloading(false)
        }, 2000)
    }, [])

    return loading ? <Loading /> : <Redirect to={`/${flow}`} />
}

const mapDispatchToProps = {
    newSession,
    getInitialHomeCover
}

export default connect(null, mapDispatchToProps)(NewQuote)
