import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import {
    Heading,
    Text,
    Alert,
    Date,
    Radio,
    Select,
    Field,
    Submit
} from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    additionalDriver: Yup.bool().required('Required'),
    additionalDriverName: Yup.string().when('additionalDriver', {
        is: true,
        then: Yup.string().required('Required')
    }),
    additionalDriverClaims: Yup.string().when('additionalDriver', {
        is: true,
        then: Yup.string()
            .required('Required')
            .oneOf(
                ['0', '1', '2', '3'],
                "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
            )
    }),
    additionalDriverDob: Yup.string().when('additionalDriver', {
        is: true,
        then: Yup.string().required('Required')
    }),
    additionalDriverLicenseLength: Yup.string().when('additionalDriver', {
        is: true,
        then: Yup.string()
            .required('Required')
            .oneOf(
                ['5'],
                "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
            )
    })
})

const AdditionalDriver = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Additional Driver Details</Heading>
            <FormManager
                useSession
                initialValues={{
                    additionalDriver: '',
                    additionalDriverName: '',
                    additionalDriverClaims: '',
                    additionalDriverDob: '',
                    additionalDriverLicenseLength: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    // Reset nested fields if initial boolean is false
                    if (values.additionalDriver === 'false') {
                        await addFacts({
                            additionalDriver: 'false',
                            additionalDriverName: '',
                            additionalDriverClaims: '',
                            additionalDriverDob: '',
                            additionalDriverLicenseLength: ''
                        })
                    } else {
                        await addFacts(values)
                    }

                    history.push(next)
                }}
            >
                {({ values, status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Radio
                            name="additionalDriver"
                            label="Are there any additional drivers of this vehicle?"
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        {values.additionalDriver === 'true' && (
                            <>
                                <Text as="p">
                                    Additional and unnamed drivers are not
                                    insured to use the vehicle for rideshare
                                    driving.
                                </Text>
                                <Field
                                    name="additionalDriverName"
                                    label="Name"
                                    required
                                />
                                <Select
                                    name="additionalDriverClaims"
                                    label="At-fault motor claims made in the last 3 years"
                                    placeholder="How many at-fault motor claims have you made in the last 3 years?"
                                    required
                                    choices={{
                                        0: 'None',
                                        1: '1',
                                        2: '2',
                                        3: '3',
                                        over3: 'More than 3'
                                    }}
                                />
                                <Date
                                    name="additionalDriverDob"
                                    label="Date of birth"
                                    required
                                    allowFuture={false}
                                    allowPast
                                    startDate={DateTime.local()
                                        .minus({ years: 100 })
                                        .toISODate()}
                                    endDate={DateTime.local()
                                        .minus({ years: 21 })
                                        .toISODate()}
                                    defaultMonth={DateTime.local()
                                        .minus({ years: 21 })
                                        .toISODate()}
                                />
                                <Select
                                    name="additionalDriverLicenseLength"
                                    label="Years licensed (excluding learners)"
                                    placeholder="Please select the number of years you have been driving after receiving your license"
                                    required
                                    choices={{
                                        0: 'Less than 1 year',
                                        1: '1 year',
                                        2: '2 years',
                                        3: '3 years',
                                        4: '4 years',
                                        5: '5 or more'
                                    }}
                                />
                            </>
                        )}
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default AdditionalDriver
