import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Radio, Text, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    hireCar: Yup.bool().required('Required'),
    glassExtras: Yup.bool().required('Required')
})

const Extras = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>
                Would you like any optional extras?
            </Heading>
            <FormManager
                useSession
                initialValues={{
                    hireCar: '',
                    glassExtras: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Radio
                            name="hireCar"
                            label="Hire car"
                            description={
                                <Text as="p" sx={{ my: 1 }}>
                                    Selecting this option provides you with
                                    access to a hire car after an accident, when
                                    you were found to be at-fault.
                                </Text>
                            }
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        <Radio
                            name="glassExtras"
                            label="Windscreen, sunroof and window damage"
                            description={
                                <Text as="p" sx={{ my: 1 }}>
                                    Selecting this option provides you with a
                                    replacement windscreen, sunroof or window,
                                    without having to pay the excess.
                                </Text>
                            }
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Extras
