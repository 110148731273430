import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Header, Loading, Link, Divider } from '@sharecover-co/ui'
import Pricing from '../base/Pricing'
import Product from '../base/Product'
import ExitModal from '../modals/ExitModal'
import { connect } from 'react-redux'
import useMediaQuery from 'use-mediaquery'
const CheckoutLayout = ({
    progress,
    title,
    menu,
    pricing,
    product,
    loading,
    children,
    state
}) => {
    const matches = useMediaQuery('(max-width: 767px)')

    return (
        <Box sx={matches ? { height: 'auto' } : { height: '100%' }}>
            <Header progress={progress} title={title} menu={menu} />
            {loading && (
                <Flex
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bg: 'rgba(255, 255, 255, 80%)',
                        color: 'primary',
                        height: '100%'
                    }}
                >
                    <Loading />
                </Flex>
            )}
            {!loading && (
                <>
                    <Flex
                        className="bdy"
                        variant="fixed"
                        sx={{
                            p: [0, 0, 0, 0, 2],
                            justifyContent: 'space-evenly',
                            flexDirection: ['column', 'column', 'row'],
                            mb: [0]
                        }}
                    >
                        <Box
                            sx={
                                matches
                                    ? {
                                          overflow: 'hidden visible',
                                          padding: '20px'
                                      }
                                    : {
                                          p: [3, 4],
                                          flex: 1,
                                          maxWidth: [
                                              'auto',
                                              'auto',
                                              'auto',
                                              600
                                          ],
                                          height: '80vh',
                                          overflow: 'auto'
                                      }
                            }
                        >
                            {children}
                        </Box>
                        <Box
                            sx={
                                matches
                                    ? {
                                          overflow: 'hidden visible',
                                          padding: '20px'
                                      }
                                    : {
                                          p: [3, 4],
                                          flex: 1,
                                          maxWidth: [
                                              'auto',
                                              'auto',
                                              'auto',
                                              600
                                          ],
                                          height: '80vh',
                                          overflow: 'auto'
                                      }
                            }
                        >
                            {pricing}
                            {product && (
                                <Box sx={{ p: [2, 3, 4], mt: 1 }}>
                                    {product}
                                </Box>
                            )}
                            <Divider sx={{ my: 0 }} />
                            <Box sx={{ p: [2, 3, 4] }}>
                                <p>
                                    <Link
                                        href={`${process.env.REACT_APP_MARKETING_URL}/contact`}
                                        target="_blank"
                                    >
                                        Contact Us
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`${process.env.REACT_APP_MARKETING_URL}/terms-of-use`}
                                        target="_blank"
                                    >
                                        By proceeding, you agree to the
                                        ShareCover website Terms of Use
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`${process.env.REACT_APP_MARKETING_URL}/complaints`}
                                        target="_blank"
                                    >
                                        Complaints Process
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        href={`${process.env.REACT_APP_MARKETING_URL}/privacy-and-security`}
                                        target="_blank"
                                    >
                                        We will collect, use and disclose your
                                        details in accordance with our privacy
                                        policy
                                    </Link>
                                </p>
                                {state.session.data.isStrata &&
                                    state.session.data.isStrata === 'false' && (
                                        <p>
                                            <Link
                                                href={`${process.env.REACT_APP_MARKETING_URL}/building-calculator`}
                                                target="_blank"
                                            >
                                                Building Sum Insured Calculator
                                            </Link>
                                        </p>
                                    )}
                                <p
                                    style={{
                                        color: 'red',

                                        fontSize: 14
                                    }}
                                >
                                    Insurance issued by Insurance Australia
                                    Limited ABN 11 000 016 722 trading as
                                    ShareCover Enterprises. To see if a product
                                    is right for you, always consider the
                                    Product Disclosure Statement and Target
                                    Market Determinations available from{' '}
                                    <Link
                                        href={`${process.env.REACT_APP_MARKETING_URL}`}
                                        target="_blank"
                                    >
                                        sharecover.com
                                    </Link>
                                </p>
                            </Box>
                        </Box>
                    </Flex>
                </>
            )}
        </Box>
    )
}
CheckoutLayout.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    progress: PropTypes.number,
    menu: PropTypes.node,
    product: PropTypes.node,
    pricing: PropTypes.node,
    loading: PropTypes.bool
}

CheckoutLayout.defaultProps = {
    title: '',
    progress: 0,
    menu: <ExitModal />,
    product: <Product />,
    pricing: <Pricing />,
    loading: false
}
const mapStateToProps = (state) => ({
    state
})

export default connect(mapStateToProps)(CheckoutLayout)
