import { connect } from 'react-redux'
import { useFormikContext } from 'formik'
import { Select } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import GrannyFlat from './GrannyFlat'
import TinyHome from './TinyHome'
import Cabin from './Cabin'
import get from 'lodash.get'
const PropertyType = (props) => {
    const formik = useFormikContext()
    let condition =
        get(formik, 'values.address.formatted', null) &&
        get(formik, 'values.isStrata', null) &&
        get(formik, 'values.hasPlatform', null) === 'true'

    return condition ? (
        <>
            <Select
                name="propertyType"
                id="propertyType"
                label="What type of building is the property?"
                required
                choices={{
                    house: 'Free standing house',
                    townhouse: 'Townhouse/villa/duplex',
                    unit: 'Unit, flat or apartment',
                    semi: 'Semi-detached house',
                    terrace: 'Terrace',
                    serviced: 'Serviced apartment',
                    grannyFlat: 'Granny Flat',
                    tinyHome: 'Tiny Home',
                    cabin: 'Cabin',
                    retirement: 'Retirement village unit',
                    temporary: 'Temporary or Relocatable Structure',
                    caravan: 'Caravan',
                    other: 'Other'
                }}
            />

            {formik.values.propertyType &&
                formik.values.propertyType === 'grannyFlat' && <GrannyFlat />}
            {formik.values.propertyType &&
                formik.values.propertyType === 'tinyHome' && <TinyHome />}
            {formik.values.propertyType &&
                formik.values.propertyType === 'cabin' && <Cabin />}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyType)
