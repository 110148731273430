import { API, graphqlOperation } from 'aws-amplify'
import get from 'lodash.get'
import { DateTime } from 'luxon'
import * as actions from './index'
import * as mutations from '../graphql/mutations'
import { preparePayload } from '../utils/session'

// quote
const quoteRequest = (data) => ({ type: actions.QUOTE_REQUEST, data })
export const quoteSuccess = (item) => ({
    type: actions.QUOTE_SUCCESS,
    item
})
const quoteFailed = (error) => ({ type: actions.QUOTE_FAILED, error })
const quoteRemove = () => ({ type: actions.QUOTE_RREMOVE })

export const quote = () => async (dispatch, getState) => {
    const {
        session: { id, productId, data, accessKey, partner }
    } = getState()
    // temporary will be fixed later
    const isRideCover = productId === 'bm8oR3Rr_3_bfSq6H_MVU'

    // required fields
    if (
        // Might need to make these product specific before making the quote api call
        ['startDate', 'endDate', 'excess'].every((k) =>
            data.hasOwnProperty(k)
        ) === false
    ) {
        return
    }

    const payload = preparePayload({ ...data })

    // Conditionally add partner info to quote
    if (partner) {
        payload.partner = partner
    }

    const input = {
        id,
        productId,
        data: JSON.stringify({
            ...payload,
            credit: 0,
            ...(!isRideCover
                ? {
                      numberOfGuests: payload.numberOfGuests * 1,
                      months: payload.months * 1,
                      postcode: payload.address.postcode,
                      state: payload.address.region,
                      buildingExcess: parseInt(payload.excess),
                      contentsExcess: parseInt(payload.excess),
                      prefiller: ''
                  }
                : {})
        }),
        startDate: DateTime.fromISO(payload.startDate).toISODate(),
        startTime: payload.time || '00:00',
        endDate: DateTime.fromISO(payload.endDate).toISODate(),
        endTime: '23:59',
        accessKey
    }

    let atProperty = JSON.parse(input.data).atProperty
    if (isRideCover || atProperty !== undefined) {
        try {
            dispatch(quoteRequest(input))
            const response = await API.graphql(
                graphqlOperation(mutations.quote, { input })
            )

            dispatch(quoteSuccess(response.data.quote))
        } catch (e) {
            dispatch(
                quoteFailed(
                    get(
                        e,
                        'errors[0].message',
                        get(e, 'message', 'Unable to price quote.')
                    )
                )
            )
            throw e
        }
    }
}

export const removeQuote = () => (dispatch, getState) => {
    dispatch(quoteRemove(null))
}
