import React from 'react'
import { connect } from 'react-redux'
import { Alert, Loading } from '@sharecover-co/ui'
import { Form } from 'formik'
import FormManager from './FormManager'
import Address from './base/Address'
import { addFacts } from '../../actions/session'
import AutoSubmitFormik from './AutoSubmitFormik'
import get from 'lodash.get'
import initialValues from './initialValues'
import initialTouched from './initialTouched'
import schema from './schema'
import PlatformAndStrata from './components/PlatformAndStrata'
import PropertyType from './components/PropertyType'
import DateAndTime from './components/DateAndTime'
import NumberOfGustsAndAtProperty from './components/NumberOfGustsAndAtProperty'
import WellMaintainedAndBooking from './components/WellMaintainedAndBooking'
import Sliders from './components/Sliders'
import PurchaseAndSubmit from './components/PurchaseAndSubmit'
import InfoSelector from './components/InfoSelector'
const QuestionsForm = (props) => {
    const [formikInitialValue, setFormikInitialValue] = React.useState(
        initialValues
    )
    return (
        <FormManager
            key={`${get(formikInitialValue, 'hasPlatform', '')}`}
            validationSchema={schema}
            // useSession
            initialValues={formikInitialValue}
            initialTouched={initialTouched}
            value={formikInitialValue}
            onSubmit={() => {
                if (
                    props.quote.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID
                ) {
                    document.querySelector('#submit-confirmation').close()
                    document
                        .querySelector('#submit-button')
                        .setAttribute('disabled', '')
                }
                window.open(
                    `${process.env.REACT_APP_PURCHASE_URL}/quote/${props.quote.id}?accessKey=${props.quote.accessKey}`,
                    '_blank'
                )
            }}
            {...props}
        >
            {({ status: { error }, handleChange }) => {
                return (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        {props.initialHomeCoverValues.loading && <Loading />}
                        <InfoSelector
                            formikInitialValue={formikInitialValue}
                            setFormikInitialValue={setFormikInitialValue}
                        />
                        <Address
                            id="address"
                            name="address.formatted"
                            label="Property Address"
                            required
                        />
                        <PlatformAndStrata />
                        <PropertyType />
                        <WellMaintainedAndBooking />
                        <DateAndTime />
                        <NumberOfGustsAndAtProperty />
                        <Sliders />
                        <PurchaseAndSubmit />
                        <AutoSubmitFormik />
                    </Form>
                )
            }}
        </FormManager>
    )
}

QuestionsForm.propTypes = {}
const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    quote: get(state, 'quote.quote', null),
    initialHomeCoverValues: get(state, 'initialHomeCoverValues', null)
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm)
