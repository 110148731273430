import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Checkbox, Select, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    usage: Yup.string().required('Required'),
    lessThan30: Yup.string().when('usage', {
        is: 'PARTTIME',
        then: Yup.string().required('Required')
    })
})

const Usage = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>How do you use your vehicle?</Heading>
            <FormManager
                useSession
                initialValues={{
                    usage: '',
                    lessThan30: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ values, status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Select
                            name="usage"
                            label="Average number of rideshare hours driven per week"
                            placeholder="Select the average number of hours you drive rideshare each week..."
                            required
                            choices={{
                                FULLTIME:
                                    'Full-time driver - more than 30 hours each week',
                                PARTTIME:
                                    'Part-time driver - less than 30 hours each week, and I acknowledge the declaration below'
                            }}
                        />
                        {values.usage === 'PARTTIME' && (
                            <Checkbox
                                name="lessThan30"
                                label="Part-time driver declaration"
                                choices={{
                                    y:
                                        'I declare that by purchasing a policy as a part-time driver, I acknowledge that I do not drive on rideshare platforms for more than 30 hours per week. If I lodge a claim I will provide my rideshare driving log for the previous 3 months. If I am found to have driven more than 30 hours my insurer may refuse to pay my claim or I will be required to pay the additional premium amount that would have been payable for a full-time driver, who drives over 30 hours per week.'
                                }}
                                required
                            />
                        )}
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Usage
