import React from 'react'
import { Text } from '@sharecover-co/ui'

const BaseConditions = () => {
    const marketingUrl =
        process.env.REACT_APP_MARKETING_URL || 'https://sharecover.com'
    const domainName = marketingUrl.replace('https://', '')
    return (
        <Text as="p">
            <small>
                Insurance issued by Insurance Australia Limited ABN 11 000 016
                722 trading as ShareCover Enterprises. To see if a product is
                right for you, always consider the Product Disclosure Statement
                and Target Market Determinations available from{' '}
                <a href={marketingUrl} target="_blank" rel="noreferrer">
                    {domainName}
                </a>
            </small>
        </Text>
    )
}

export default BaseConditions
