import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { DateTime } from 'luxon'
import { Heading, Alert, Date, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    startDate: Yup.string().required('Required')
})

const Dates = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>
                When would you like to start your coverage?
            </Heading>
            <FormManager
                useSession
                initialValues={{
                    startDate: DateTime.local().toISODate()
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts({
                        ...values,
                        endDate: DateTime.fromISO(values.startDate)
                            .plus({ year: 1 })
                            .minus({ day: 1 })
                            .toISODate()
                    })
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Date
                            name="startDate"
                            label="Start Date"
                            required
                            startDate={DateTime.local().toISODate()}
                            endDate={DateTime.local()
                                .plus({ days: 21 })
                                .toISODate()}
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Dates
