import React from 'react'
import { connect } from 'react-redux'
import { addFacts } from '../../../actions/session'
import {
    NumberOfNights,
    ChangeHandler
} from '../../../components/forms/base/DateAndTimeHandlers'
import getTimes from '../../../components/forms/base/getTimes'
import { Date, Select } from '@sharecover-co/ui'
import { DateTime } from 'luxon'
import get from 'lodash.get'
import { useFormikContext } from 'formik'
import handlePolicyTerm from '../../../utils/handlePolicyTerm'
import isToday from '../../../utils/isToday'
import TotalModal from '../../modals/TotalModal'

const DateAndTime = (props) => {
    const formik = useFormikContext()
    let condition =
        get(props, 'data.address.formatted', null) &&
        get(props, 'data.isStrata', null) &&
        get(props, 'data.hasPlatform', null) === 'true' &&
        get(props, 'data.propertyType', null) &&
        get(props, 'data.propertyType', null) !== 'other' &&
        get(props, 'data.propertyType', null) !== 'caravan' &&
        get(props, 'data.propertyType', null) !== 'temporary' &&
        get(props, 'data.propertyType', null) !== 'retirement' &&
        (get(props, 'data.policy', null) ||
            (get(props, 'data.wellMaintained', null) &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID))

    const [isStartDayToday, setIsStartDayToday] = React.useState(false)

    React.useEffect(() => {
        if (
            props.product.id === process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID
        ) {
            formik.setFieldValue('time', '')
        }
    }, [])
    React.useEffect(() => {
        if (
            props.data.startDate &&
            props.product.id === process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID
        ) {
            handlePolicyTerm(
                { target: { value: props.data.months } },
                props,
                formik
            )
        }
        formik.setFieldValue('time', '')
        if (isToday(props.data.startDate)) {
            setIsStartDayToday(true)
        } else {
            setIsStartDayToday(false)
        }
    }, [props.data.startDate])

    return condition ? (
        <>
            <Date
                name="startDate"
                id="startDate"
                label="Start Date"
                required
                allowPast={false}
                allowFuture={true}
                startDate={DateTime.local().toISODate()}
                endDate={
                    props.data.endDate
                        ? DateTime.fromISO(props.data.endDate)
                              .minus({ days: 1 })
                              .toISODate()
                        : DateTime.local()
                              .plus({
                                  days: 90
                              })
                              .toISODate()
                }
            />
            <TotalModal
                question="We do not cover accommodation bookings that have already commenced when your policy starts, except in limited circumstances"
                content={
                    <>
                        <p>
                            We do not provide cover for any accommodation
                            bookings that have already commenced when your
                            policy starts, unless your policy commences
                            immediately after the end of another ShareCover
                            Short Stay policy or ShareCover pay-per-night
                            Short-Stay policy that you held for your home, or a
                            similar policy that covered your Home while it was
                            being used as short term accommodation, with no gap
                            in cover.
                        </p>
                        <p>
                            For example, if you are applying for a policy to
                            start today but you currently have a booking that
                            has already commenced at this address, we will not
                            be able to provide you cover unless the limited
                            circumstances stated above apply. However you could
                            choose to apply for a policy to start from the start
                            date of your next booking.
                        </p>
                    </>
                }
            />
            {props.product.id !==
                process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID &&
                isStartDayToday && (
                    <Select
                        name="time"
                        id="time"
                        label="Start Time"
                        required
                        choices={getTimes(
                            props.data.startDate,
                            props.data.address.region
                        )}
                    />
                )}
            {props.data.startDate &&
                props.product.id !==
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <Select
                        name="days"
                        id="days"
                        label="Number of nights"
                        choices={{
                            0: 'Not Selected',
                            7: ' 7 nights ',
                            30: ' 30 nights ',
                            90: ' 90 nights '
                        }}
                    />
                )}
            {props.data.startDate &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <Select
                        name="months"
                        id="months"
                        label="Policy Term"
                        required
                        choices={{
                            0: 'Not Selected',
                            6: ' 6 months ',
                            12: ' 12 months '
                        }}
                        onChange={(e) => {
                            handlePolicyTerm(e, props, formik)
                        }}
                    />
                )}

            {props.data.startDate &&
                props.product.id !==
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <>
                        <Date
                            name="endDate"
                            id="endDate"
                            label="End Date"
                            required
                            disabled={!props.data.startDate}
                            allowPast={false}
                            allowFuture={true}
                            startDate={DateTime.fromISO(props.data.startDate)
                                .plus({ days: 1 })
                                .toISODate()}
                            endDate={DateTime.fromISO(props.data.startDate)
                                .plus({ days: 90 })
                                .toISODate()}
                        />
                        <NumberOfNights />
                    </>
                )}

            <ChangeHandler />
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    product: get(state, 'product.product', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(DateAndTime)
