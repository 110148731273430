/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCharge = /* GraphQL */ `
  query GetCharge($id: ID!) {
    getCharge(id: $id) {
      amount
      createdAt
      id
      meta
      paymentMethodId
      success
      tx
      updatedAt
      userId
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      createdAt
      data
      documents {
        key
        name
        url
      }
      id
      lossCodes
      policy {
        cancelledAt
        createdAt
        data
        description
        endDate
        endTime
        id
        paymentMethod {
          cardType
          createdAt
          expirationMonth
          expirationYear
          gateway
          id
          last4
          token
          userId
        }
        paymentMethodId
        paymentSchedule
        payments {
          nextToken
        }
        premium {
          price
          tax
          total
        }
        product {
          claimFlowId
          createdAt
          description
          id
          name
          policyDescriptionPath
          policyIdPrefix
          premiumPriceId
          quoteFlowId
          unitPriceId
          updatedAt
        }
        productId
        quoteId
        startDate
        startTime
        unit {
          price
          tax
          total
        }
        updatedAt
        userId
      }
      policyId
      status
      updatedAt
      user {
        address {
          city
          country
          formatted
          isManualAddress
          placeId
          postcode
          region
          streetAddress
          suiteAddress
          town
        }
        createdAt
        dob
        email
        firstName
        id
        lastName
        phone
        updatedAt
      }
      userId
    }
  }
`;
export const getPaymentMethod = /* GraphQL */ `
  query GetPaymentMethod($id: ID!) {
    getPaymentMethod(id: $id) {
      cardType
      createdAt
      expirationMonth
      expirationYear
      gateway
      id
      last4
      token
      userId
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      cancelledAt
      createdAt
      data
      description
      endDate
      endTime
      id
      paymentMethod {
        cardType
        createdAt
        expirationMonth
        expirationYear
        gateway
        id
        last4
        token
        userId
      }
      paymentMethodId
      paymentSchedule
      payments {
        items {
          chargeId
          chargedAt
          createdAt
          dueAt
          failedAt
          policyId
          price
          scheduledDate
          scheduledTime
          tax
          total
          updatedAt
        }
        nextToken
      }
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      productId
      quoteId
      startDate
      startTime
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      updatedAt
      userId
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      claimFlow {
        createdAt
        fields {
          config
          format
          name
          path
        }
        id
        lossCodes {
          name
        }
        name
        updatedAt
        url
      }
      claimFlowId
      createdAt
      description
      documents {
        key
        name
        url
      }
      id
      name
      policyDescriptionPath
      policyIdPrefix
      premiumPriceId
      quoteFlow {
        createdAt
        fields {
          config
          format
          name
          path
        }
        id
        lossCodes {
          name
        }
        name
        updatedAt
        url
      }
      quoteFlowId
      unitPriceId
      updatedAt
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      address {
        city
        country
        formatted
        isManualAddress
        placeId
        postcode
        region
        streetAddress
        suiteAddress
        town
      }
      createdAt
      dob
      email
      firstName
      id
      lastName
      phone
      updatedAt
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($accessKey: ID, $id: ID!) {
    getQuote(accessKey: $accessKey, id: $id) {
      accessKey
      createdAt
      data
      endDate
      endTime
      id
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      startDate
      startTime
      ttl
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      userId
    }
  }
`;
export const getStatement = /* GraphQL */ `
  query GetStatement($id: ID!) {
    getStatement(id: $id) {
      createdAt
      details {
        endDate
        quantity
        startDate
        tax
        taxes {
          rate
          tax
        }
        total
        unit {
          price
          tax
          total
        }
        usage {
          createdAt
          endDate
          id
          meta
          policyId
          startDate
        }
        usageId
      }
      endDate
      id
      policy {
        cancelledAt
        createdAt
        data
        description
        endDate
        endTime
        id
        paymentMethod {
          cardType
          createdAt
          expirationMonth
          expirationYear
          gateway
          id
          last4
          token
          userId
        }
        paymentMethodId
        paymentSchedule
        payments {
          nextToken
        }
        premium {
          price
          tax
          total
        }
        product {
          claimFlowId
          createdAt
          description
          id
          name
          policyDescriptionPath
          policyIdPrefix
          premiumPriceId
          quoteFlowId
          unitPriceId
          updatedAt
        }
        productId
        quoteId
        startDate
        startTime
        unit {
          price
          tax
          total
        }
        updatedAt
        userId
      }
      policyId
      startDate
      tax
      taxes {
        rate
        tax
      }
      total
      userId
    }
  }
`;
export const listCharges = /* GraphQL */ `
  query ListCharges {
    listCharges {
      items {
        amount
        createdAt
        id
        meta
        paymentMethodId
        success
        tx
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims {
    listClaims {
      items {
        createdAt
        data
        documents {
          key
          name
          url
        }
        id
        lossCodes
        policy {
          cancelledAt
          createdAt
          data
          description
          endDate
          endTime
          id
          paymentMethodId
          paymentSchedule
          productId
          quoteId
          startDate
          startTime
          updatedAt
          userId
        }
        policyId
        status
        updatedAt
        user {
          createdAt
          dob
          email
          firstName
          id
          lastName
          phone
          updatedAt
        }
        userId
      }
      nextToken
    }
  }
`;
export const listPaymentMethods = /* GraphQL */ `
  query ListPaymentMethods {
    listPaymentMethods {
      cardType
      createdAt
      expirationMonth
      expirationYear
      gateway
      id
      last4
      token
      userId
    }
  }
`;
export const listPolicies = /* GraphQL */ `
  query ListPolicies {
    listPolicies {
      cancelledAt
      createdAt
      data
      description
      endDate
      endTime
      id
      paymentMethod {
        cardType
        createdAt
        expirationMonth
        expirationYear
        gateway
        id
        last4
        token
        userId
      }
      paymentMethodId
      paymentSchedule
      payments {
        items {
          chargeId
          chargedAt
          createdAt
          dueAt
          failedAt
          policyId
          price
          scheduledDate
          scheduledTime
          tax
          total
          updatedAt
        }
        nextToken
      }
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      productId
      quoteId
      startDate
      startTime
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      updatedAt
      userId
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts {
    listProducts {
      claimFlow {
        createdAt
        fields {
          config
          format
          name
          path
        }
        id
        lossCodes {
          name
        }
        name
        updatedAt
        url
      }
      claimFlowId
      createdAt
      description
      documents {
        key
        name
        url
      }
      id
      name
      policyDescriptionPath
      policyIdPrefix
      premiumPriceId
      quoteFlow {
        createdAt
        fields {
          config
          format
          name
          path
        }
        id
        lossCodes {
          name
        }
        name
        updatedAt
        url
      }
      quoteFlowId
      unitPriceId
      updatedAt
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes {
    listQuotes {
      accessKey
      createdAt
      data
      endDate
      endTime
      id
      premium {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      product {
        claimFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        claimFlowId
        createdAt
        description
        documents {
          key
          name
          url
        }
        id
        name
        policyDescriptionPath
        policyIdPrefix
        premiumPriceId
        quoteFlow {
          createdAt
          id
          name
          updatedAt
          url
        }
        quoteFlowId
        unitPriceId
        updatedAt
      }
      startDate
      startTime
      ttl
      unit {
        price
        tax
        taxes {
          price
          tax
        }
        total
      }
      userId
    }
  }
`;
export const listStatements = /* GraphQL */ `
  query ListStatements {
    listStatements {
      createdAt
      details {
        endDate
        quantity
        startDate
        tax
        taxes {
          rate
          tax
        }
        total
        unit {
          price
          tax
          total
        }
        usage {
          createdAt
          endDate
          id
          meta
          policyId
          startDate
        }
        usageId
      }
      endDate
      id
      policy {
        cancelledAt
        createdAt
        data
        description
        endDate
        endTime
        id
        paymentMethod {
          cardType
          createdAt
          expirationMonth
          expirationYear
          gateway
          id
          last4
          token
          userId
        }
        paymentMethodId
        paymentSchedule
        payments {
          nextToken
        }
        premium {
          price
          tax
          total
        }
        product {
          claimFlowId
          createdAt
          description
          id
          name
          policyDescriptionPath
          policyIdPrefix
          premiumPriceId
          quoteFlowId
          unitPriceId
          updatedAt
        }
        productId
        quoteId
        startDate
        startTime
        unit {
          price
          tax
          total
        }
        updatedAt
        userId
      }
      policyId
      startDate
      tax
      taxes {
        rate
        tax
      }
      total
      userId
    }
  }
`;
