import { connect } from 'react-redux'
import { Slider } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
import SumInsuredModal from '../../modals/SumInsuredModal'
import {
    formatCurrency,
    getMin,
    getMax,
    getBuildingMarks
} from '../../../components/forms/base/BuildingCoverHandler'
import TotalModal from '../../modals/TotalModal'
const Sliders = (props) => {
    let condition =
        get(props, 'data.address.formatted', null) &&
        get(props, 'data.isStrata', null) &&
        get(props, 'data.hasPlatform', null) === 'true' &&
        get(props, 'data.propertyType', null) &&
        get(props, 'data.propertyType', null) !== 'other' &&
        get(props, 'data.propertyType', null) !== 'caravan' &&
        get(props, 'data.propertyType', null) !== 'temporary' &&
        get(props, 'data.propertyType', null) !== 'retirement' &&
        (get(props, 'data.policy', null) ||
            (get(props, 'data.wellMaintained', null) &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID)) &&
        get(props, 'data.endDate', null) &&
        get(props, 'data.numberOfGuests', null) &&
        get(props, 'data.atProperty', null)
    return condition ? (
        <>
            {props.data.isStrata === 'false' && (
                <SumInsuredModal
                    question="Need help with the sum insured?"
                    content={
                        <p>
                            You should make sure you insure your buildings for
                            their full replacement value. The building sum
                            insured applies to all domestic buildings at your
                            site, not just the area leased for Pay Per Night.
                            Here's a building{' '}
                            <a
                                href={`https://sumsure.cordell.com.au/#/products/7/profiles/125?partner=12504`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                sum insured calculator
                            </a>{' '}
                            to help with this.
                        </p>
                    }
                />
            )}
            {props.data.isStrata === 'true' && (
                <SumInsuredModal
                    question="Need help with the sum insured?"
                    content="You should make sure you insure your apartment, flat, unit or townhouse for its full replacement value. The building sum insured for a company share, stratum or strata title property extends to:
1) blinds, curtains and window coverings within the apartment, flat, unit or townhouse; and
2) internal fixtures and fittings within the apartment, flat, unit or townhouse."
                />
            )}
            <Slider
                key={`building-${props.data.isStrata}`}
                id="building"
                name="building"
                label="Building Cover"
                required
                preview={(v) => formatCurrency(v)}
                step={null}
                min={getMin(props.data.isStrata)}
                max={getMax(props.data.isStrata)}
                marks={getBuildingMarks(props.data.isStrata)}
            />

            <Slider
                name="contents"
                id="contents"
                label="Contents Cover"
                required
                preview={(v) => formatCurrency(v)}
                min={30000}
                max={500000}
                step={10000}
            />
            <Slider
                name="excess"
                id="excess"
                label="Excess Amount"
                required
                preview={(v) => formatCurrency(v)}
                min={100}
                max={1500}
                step={100}
            ></Slider>
            <TotalModal
                question="How does this work?"
                title="Excess Amount"
                content="The amount you will contribute toward each claim except under Section 2: Liability Cover. Increasing your excess will typically reduce your premium."
            />
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    product: get(state, 'product.product', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(Sliders)
