import omit from 'lodash.omit'
import { nanoid, customAlphabet } from 'nanoid'
import * as actions from './'
import { getProduct } from './product'
import { quote } from './quote'
import { isEmpty } from 'lodash'
const generateId = customAlphabet('0123456789ABCDEFGHJKLMNPQRTUVWXYZ', 18)

export const newSession = (data = {}) => async (dispatch) => {
    // if we have a data qs it should be base64 encoded
    if (
        data.data &&
        !isEmpty(data.data) &&
        data.data?.match(
            /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/
        )
    ) {
        const bufBaby = Buffer.from(data.data, 'base64').toString()
        data.data = JSON.parse(bufBaby)
    }

    dispatch({
        type: actions.NEW_SESSION,
        data: {
            ...data,
            id: generateId(),
            accessKey: nanoid(),
            skip: data.skip ? data.skip.split(',') : undefined // skip routes
        }
    })

    if (data.productId) {
        await dispatch(getProduct(data.productId))
    }
}

export const addFacts = (values, replace = true) => async (
    dispatch,
    getState
) => {
    const { data: currentValues } = getState().session
    const data = replace ? values : omit(values, Object.keys(currentValues))
    // dont bother if nothing has changed
    if (
        Object.keys(data).filter((k) => data[k] !== currentValues[k]).length ===
        0
    ) {
        return
    }

    await dispatch({ type: actions.ADD_FACTS, data })
    setTimeout(() => {
        dispatch(quote())
    }, 1000)
}
