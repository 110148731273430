import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Field, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    postcode: Yup.string()
        .required('Required')
        .matches(
            /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/,
            'Should be a valid Australian postcode'
        ) // Postal codes of the form 'DDDD', with the first two digits 02, 08 or 20-97. Leading 0 may be omitted. 909 and 0909 are valid as well - but no other postal codes starting with 9 or 09.
})

const ParkedAddress = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>
                Where do you keep your car parked at home?
            </Heading>
            <FormManager
                useSession
                initialValues={{
                    postcode: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Field
                            name="postcode"
                            label="Residential postcode"
                            required
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default ParkedAddress
