import React from 'react'
import { useModal } from 'react-modal-hook'
import { Heading, Text, Button, Modal } from '@sharecover-co/ui'

export default ({ title = '', content = '', question = '' }) => {
    const [showModal, hideModal] = useModal(() => (
        <Modal onRequestClose={hideModal}>
            <Heading as="h4">{title}</Heading>
            <Text>{content}</Text>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <Button sx={{ mt: 3 }} onClick={hideModal}>
                    OK
                </Button>
            </div>
        </Modal>
    ))

    return (
        <Button variant="text" type="button" onClick={showModal}>
            {question}
        </Button>
    )
}
