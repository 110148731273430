import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Field, Radio, Select, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    rego: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    hasFinance: Yup.bool().required('Required'),
    finance: Yup.string().when('hasFinance', {
        is: true,
        then: Yup.string().required('Required')
    }),
    financeCompany: Yup.string().when('hasFinance', {
        is: true,
        then: Yup.string()
    })
})

const Registration = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Vehicle Registration</Heading>
            <FormManager
                useSession
                initialValues={{
                    rego: '',
                    state: '',
                    hasFinance: '',
                    finance: '',
                    financeCompany: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts({
                        ...values,
                        finance: values.finance || 'No finance'
                    })
                    history.push(next)
                }}
            >
                {({ values, status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Field
                            name="rego"
                            label="Vehicle registration number"
                            required
                        />
                        <Select
                            name="state"
                            label="State of Registration"
                            placeholder="Select"
                            required
                            choices={{
                                ACT: 'ACT',
                                NSW: 'NSW',
                                NT: 'NT',
                                QLD: 'QLD',
                                SA: 'SA',
                                TAS: 'TAS',
                                VIC: 'VIC',
                                WA: 'WA'
                            }}
                        />
                        <Radio
                            name="hasFinance"
                            label="Vehicle Finance"
                            choices={{
                                [true]: 'Yes',
                                [false]: 'No'
                            }}
                            required
                        />
                        {values.hasFinance === 'true' && (
                            <>
                                <Select
                                    name="finance"
                                    label="Vehicle Finance type"
                                    placeholder="Please add your vehicle finance type ..."
                                    required
                                    choices={{
                                        Loan: 'Bank or Credit Union Loan',
                                        Leased: 'Lease',
                                        Other: 'Other'
                                    }}
                                />
                                <Field
                                    name="financeCompany"
                                    label="Finance company"
                                    placeholder="Please add which company you have a loan or lease with ... "
                                />
                            </>
                        )}
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Registration
