import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { vehicleLookup } from '../../../actions/vehicle'

const getSeries = ({ year, make, model, variant }) =>
    vehicleLookup({
        lookup: 'series',
        year,
        make,
        model,
        variant
    })

const VehicleSeries = (props) => {
    const [series, setSeries] = useState([])
    const [field] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (values.year && values.make && values.model && values.variant) {
            getSeries(values).then((data) => {
                setSeries(data)

                // reset value as its not in our new set
                if (!data.includes(values[field.name])) {
                    setFieldValue(field.name, '')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.year, values.make, values.model, values.variant])

    if (!values.variant) {
        return null
    }

    const choices = Object.fromEntries(series.map((m) => [m, m]))

    return <Select {...field} {...props} choices={choices} />
}

VehicleSeries.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string
}

VehicleSeries.defaultProps = {
    name: 'series',
    label: 'Series',
    required: true,
    placeholder: 'Please select a series...'
}

export default VehicleSeries
