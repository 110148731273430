import React from 'react'
import { Redirect } from 'react-router-dom'
import { getInitialHomeCover } from '../../actions/initialHomeCoverValues'
import { connect } from 'react-redux'
import { newSession } from '../../actions/session'
const Redirector = ({ newSession, state, getInitialHomeCover }) => {
    getInitialHomeCover()
    newSession({ ...state.session })
    return <Redirect to={`/${state.session.flow}`} />
}

const mapStateToProps = (state) => ({
    state
})

const mapDispatchToProps = {
    getInitialHomeCover,
    newSession
}

export default connect(mapStateToProps, mapDispatchToProps)(Redirector)
