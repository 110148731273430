import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { vehicleLookup } from '../../../actions/vehicle'

const getStyles = ({ year, make, model, variant, series }) =>
    vehicleLookup({
        lookup: 'style',
        year,
        make,
        model,
        variant,
        series
    })

const VehicleStyle = (props) => {
    const [styles, setStyles] = useState([])
    const [field] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (
            values.year &&
            values.make &&
            values.model &&
            values.variant &&
            values.series
        ) {
            getStyles(values).then((data) => {
                setStyles(data)

                // reset value as its not in our new set
                if (!data.includes(values[field.name])) {
                    setFieldValue(field.name, '')
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.year, values.make, values.model, values.variant, values.series])

    if (!values.series) {
        return null
    }

    const choices = Object.fromEntries(styles.map((m) => [m, m]))

    return <Select {...field} {...props} choices={choices} />
}

VehicleStyle.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string
}

VehicleStyle.defaultProps = {
    name: 'style',
    label: 'Style',
    required: true,
    placeholder: 'Please select a style...'
}

export default VehicleStyle
