import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Text, Alert, Checkbox, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'
import VehicleMarketValue from '../../forms/base/VehicleMarketValue'

const schema = Yup.object().shape({
    marketValue: Yup.string().required('Required'),
    marketValueKnockout: Yup.array()
        .required('Required')
        .test('required', 'This is required.', (value) =>
            (value || []).includes('true')
        )
})

const formatCurrency = (n) =>
    new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0
    }).format(n)

const InsuredValue = ({ next, session, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Vehicle Details</Heading>
            <FormManager
                useSession
                initialValues={{
                    marketValue: '',
                    marketValueKnockout: []
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ values, status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <VehicleMarketValue
                            required
                            disabled
                            vehicle={{
                                year: session.data.year,
                                make: session.data.make,
                                model: session.data.model,
                                variant: session.data.variant,
                                series: session.data.series,
                                style: session.data.style
                            }}
                        />

                        <Heading
                            as="h4"
                            style={{ marginTop: '-2rem', marginBottom: '2rem' }}
                        >
                            {formatCurrency(values.marketValue)}
                        </Heading>

                        <Text as="p">
                            The maximum market value that this policy covers is
                            $70,000. At the time of an incident, we will
                            calculate the market value using local market
                            vehicle prices and take into consideration the age
                            and condition of your vehicle and any modifications,
                            options or accessories attached to it.
                        </Text>
                        <Text as="p">
                            Check the PDS in the Helpful Documents section of
                            this page for more information.
                        </Text>

                        <Checkbox
                            name="marketValueKnockout"
                            choices={{
                                [true]: 'I understand that that the value of my car will be based on the market value at the time of an accident and the value will be capped at $70,000.'
                            }}
                            required
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

const mapStateToProps = ({ session }) => ({ session })

export default connect(mapStateToProps)(InsuredValue)
