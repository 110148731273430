import React from 'react'
import { useModal } from 'react-modal-hook'
import { Flex, Heading, Button, Link, Modal } from '@sharecover-co/ui'
import { connect } from 'react-redux'
import get from 'lodash.get'
const ExitModal = (props) => {
    const [authenticated, setAuthenticated] = React.useState(false)
    React.useEffect(() => {
        let temp = get(
            props,
            'state.initialHomeCoverValues.initialHomecover.length',
            null
        )
        if (temp) {
            setAuthenticated(true)
        }
    }, [props.state.initialHomeCoverValues.initialHomecover])

    const [showModalAuthTrue, hideModalAuthTrue] = useModal(() => (
        <Modal onRequestClose={hideModalAuthTrue}>
            <Heading as="h4">Are you sure?</Heading>
            <Flex sx={{ mt: 3, mx: -1 }}>
                <Link
                    href={`${process.env.REACT_APP_MARKETING_URL}?auth=true`}
                    sx={{
                        variant: 'links.button',
                        flex: 1,
                        minWidth: '0',
                        mx: 1
                    }}
                >
                    Exit
                </Link>
                <Button
                    onClick={hideModalAuthTrue}
                    variant="buttons.outline"
                    sx={{ flex: 1, minWidth: '0', mx: 1 }}
                >
                    Cancel
                </Button>
            </Flex>
        </Modal>
    ))
    const [showModalAuthFalse, hideModalAuthFalse] = useModal(() => (
        <Modal onRequestClose={hideModalAuthFalse}>
            <Heading as="h4">Are you sure?</Heading>
            <Flex sx={{ mt: 3, mx: -1 }}>
                <Link
                    href={`${process.env.REACT_APP_MARKETING_URL}?auth=false`}
                    sx={{
                        variant: 'links.button',
                        flex: 1,
                        minWidth: '0',
                        mx: 1
                    }}
                >
                    Exit
                </Link>
                <Button
                    onClick={hideModalAuthFalse}
                    variant="buttons.outline"
                    sx={{ flex: 1, minWidth: '0', mx: 1 }}
                >
                    Cancel
                </Button>
            </Flex>
        </Modal>
    ))
    return authenticated ? (
        <Button
            variant="text"
            type="button"
            onClick={showModalAuthTrue}
            sx={{ py: 0, my: 0, lineHeight: 1 }}
        >
            Exit Quote
        </Button>
    ) : (
        <Button
            variant="text"
            type="button"
            onClick={showModalAuthFalse}
            sx={{ py: 0, my: 0, lineHeight: 1 }}
        >
            Exit Quote
        </Button>
    )
}

const mapStateToProps = (state) => ({
    state
})

export default connect(mapStateToProps)(ExitModal)
