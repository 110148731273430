import { DateTime } from 'luxon'

export const daysDiff = (startDate, endDate) => {
    if (startDate && endDate) {
        const currentStartDate = DateTime.fromISO(startDate)
        const currentEndDate = DateTime.fromISO(endDate)
        return currentEndDate.diff(currentStartDate, 'days').days
    }
    return undefined
}
