import React from 'react'
import { connect } from 'react-redux'
import {
    Flex,
    Box,
    Heading,
    Text,
    Icon,
    Loading,
    parseFields
    //  BundleTag
} from '@sharecover-co/ui'
import get from 'lodash.get'
import { daysDiff } from '../../utils/dates'
//import { getBundleType } from '../../utils/bundles'

const formatCurrency = (n) =>
    new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD'
    }).format(n)

const getNoOfNights = (data) => {
    return daysDiff(data['startDate'], data['endDate'])
}

const Pricing = ({
    quoteFlowUrl,
    fields,
    loading,
    quote,
    error,
    productId
}) => {
    // This is horrible! Sorry. (and is temporary!)
    const isProperty = quoteFlowUrl && quoteFlowUrl.indexOf('property') !== -1
    const data = quote?.data
    return (
        <Box variant="boxed">
            {loading && (
                <Flex
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        bg: 'rgba(255, 255, 255, 80%)',
                        color: 'primary'
                    }}
                >
                    <Loading />
                </Flex>
            )}
            {quote && quote.premium && (
                <Box sx={{ mb: 4 }}>
                    <Heading as="h4">
                        {formatCurrency(quote.premium.total)}
                    </Heading>
                    <Text sx={{ mb: 2 }}>Premium Total (includes taxes)</Text>
                </Box>
            )}
            {(!quote || !quote.data) && !error && (
                <Text
                    sx={{
                        mb: 4,
                        fontSize: [0],
                        color: 'primary',
                        alignItems: 'center'
                    }}
                >
                    We need a few more details to work out a price.
                </Text>
            )}
            {error && !quote && (
                <Flex
                    sx={{
                        mb: 4,
                        fontSize: 2,
                        color: 'error',
                        alignItems: 'center'
                    }}
                >
                    <Icon icon={['fal', 'exclamation-circle']} />
                    <Text sx={{ fontSize: [0], ml: 1 }}>{error}</Text>
                </Flex>
            )}
            {/* {quote &&
                quote.product.name === 'HomeCover Short Stay' &&
                quote['endDate'] &&
                getBundleType(getNoOfNights(data)) && (
                    <span>
                        <Text sx={{ fontWeight: 'bold', color: 'red' }}>
                            <BundleTag
                                bundleType={getBundleType(getNoOfNights(data))}
                            />
                        </Text>
                    </span>
                )} */}
            {/* this part is for bundle */}
            {parseFields(data, fields).map(([key, val]) => (
                <Flex
                    key={key}
                    sx={{
                        borderBottom: '2px solid',
                        borderBottomColor: 'lightGrey',
                        mt: 1,
                        pb: 1,
                        ':last-child': {
                            borderBottom: 'none',
                            pb: 0
                        },
                        '> strong': {
                            flex: 1
                        },
                        '> span': {
                            textAlign: 'right',
                            whiteSpace: 'pre-line'
                        }
                    }}
                >
                    <strong>{key}</strong>
                    <span>{val}</span>
                </Flex>
            ))}
            {isProperty &&
                data &&
                data.startDate &&
                data.endDate &&
                getNoOfNights(data) &&
                productId !==
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <Flex
                        key="nights"
                        sx={{
                            borderBottom: '2px solid',
                            borderBottomColor: 'lightGrey',
                            mt: 1,
                            pb: 1,
                            ':last-child': {
                                borderBottom: 'none',
                                pb: 0
                            },
                            '> strong': {
                                flex: 1
                            },
                            '> span': {
                                textAlign: 'right',
                                whiteSpace: 'pre-line'
                            }
                        }}
                    >
                        <strong>No. Nights</strong>
                        <span>
                            {daysDiff(data['startDate'], data['endDate'])}
                        </span>
                    </Flex>
                )}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    loading: state.quote.loading,
    quoteFlowUrl: get(state, 'product.product.quoteFlow.url', []),
    fields: get(state, 'product.product.quoteFlow.fields', []),
    productId: get(state, 'product.product.id', []),
    quote: get(state, 'quote.quote', null),
    error: state.quote.error
})

export default connect(mapStateToProps)(Pricing)
