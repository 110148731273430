import React from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Loading } from '@sharecover-co/ui'
import { addFacts } from '../../actions/session'
import { removeQuote } from '../../actions/quote'
import CheckoutLayout from '../layouts/CheckoutLayout'
import Pricing from '../base/Pricing'
import QuestionsForm from '../forms/QuestionsForm'
import { newSession } from '../../actions/session'
import useMediaQuery from 'use-mediaquery'
const Property = ({ loading, removeQuote, location: { search } }) => {
    React.useEffect(() => {
        removeQuote()
    }, [])
    const matches = useMediaQuery('(max-width: 767px)')
    if (loading) {
        return (
            <Flex
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bg: 'rgba(255, 255, 255, 80%)',
                    color: 'primary'
                }}
            >
                <Loading />
            </Flex>
        )
    }
    return (
        <Box
            sx={
                matches
                    ? {
                          boxSizing: 'border-box',
                          margin: '0px',
                          minWidth: '0px',
                          width: '100%'
                      }
                    : {
                          boxSizing: 'border-box',
                          margin: '0px',
                          minWidth: '0px',
                          width: '100%',

                          position: 'fixed'
                          // height: '100vh'
                      }
            }
        >
            <CheckoutLayout
                title={'Quote'}
                progress={100}
                pricing={<Pricing />}
            >
                <QuestionsForm />
            </CheckoutLayout>
        </Box>
    )
}

const mapDispatchToProps = {
    addFacts,
    newSession,
    removeQuote
}

const mapStateToProps = (state) => ({
    product: state.product.product,
    loading: state.product.loading,
    session: state.session,
    error: state.quote.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Property)
