import Auth from '@aws-amplify/auth'
import * as actions from './'

export const logout = () => async (dispatch) => {
    try {
        await Auth.signOut()

        dispatch({ type: actions.LOGOUT })
    } catch (e) {
        console.log(e)
    }
}

export const isAuthenticated = () => async (dispatch) => {
    try {
        const credentials = await Auth.currentCredentials()

        if (!credentials.authenticated) {
            throw new Error('Not authenticated.')
        }

        dispatch({ type: actions.AUTHED, id: credentials.identityId })
    } catch (e) {
        console.log(e)
        dispatch({ type: actions.UNAUTHENTICATED })
    }
}

export const signIn = (email, password) => async (dispatch) => {
    try {
        await Auth.signIn(email, password)
        const credentials = await Auth.currentCredentials()

        dispatch({ type: actions.AUTHED, id: credentials.identityId })
    } catch (e) {
        console.log(e)
        dispatch({ type: actions.UNAUTHENTICATED })
    }
}
