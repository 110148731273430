import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import debounce from 'lodash.debounce'
import { addFacts } from '../../actions/session'
import get from 'lodash.get'
import { connect } from 'react-redux'
const AutoSubmitFormik = (props) => {
    const formik = useFormikContext()
    const debouncedSubmit = debounce(() => {
        if (formik.dirty) {
            props.addFacts(formik.values)
        } else {
            props.addFacts(props.data)
        }
    })

    useEffect(() => {
        debouncedSubmit()
    }, [formik.values, debouncedSubmit])
    return null
}
const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoSubmitFormik)
