import React from 'react'
import { connect } from 'react-redux'
import { newSession } from '../../actions/session'
import ProtectedRoute from '../../components/hooks/ProtectedRoute'
import Redirector from '../views/Redirector'
import withTracker from '../../utils/withTracker'
import IsAuthenticated from '../../components/hooks/IsAuthenticated'
import qs from 'qs'
const Login = ({
    newSession,
    location: { search },
    match: {
        params: { flow }
    }
}) => {
    const params = qs.parse(search, { ignoreQueryPrefix: true })
    newSession({ ...params, flow })
    return (
        <IsAuthenticated>
            <ProtectedRoute path="/" component={withTracker(Redirector)} />
        </IsAuthenticated>
    )
}

const mapDispatchToProps = {
    newSession
}

export default connect(null, mapDispatchToProps)(Login)
