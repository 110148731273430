import { useFormikContext } from 'formik'
import { connect } from 'react-redux'
import { Radio } from '@sharecover-co/ui'
import TotalModal from './../../modals/TotalModal'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const PlatformAndStrata = (props) => {
    const formik = useFormikContext()

    let condition = get(formik, 'values.address.formatted', null)
    return condition ? (
        <>
            <Radio
                id="hasPlatform"
                name="hasPlatform"
                label="Do you use a Recognised Platform for the listing of your property?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            <TotalModal
                question="What is a Recognised Platform?"
                content={`Recognised Platform” means a digital website that, or a suitably qualified real estate agent who, facilitates a transaction for Temporary Residential Accommodation between You and a Guest and carries out Verification Checks on the Guest making the booking.
Social media platforms and online classified listings are not considered to be recognised platforms.`}
            />
            {formik.values.hasPlatform === 'true' && (
                <Radio
                    name="isStrata"
                    id="isStrata"
                    label="Is your property part of company share, strata title or strata arrangement?"
                    required
                    onChange={async (e) => {
                        await props.addFacts(formik.values)
                        formik.setFieldValue(
                            'building',
                            e.target.value === 'true' ? 60000 : 150000
                        )
                        formik.handleChange(e)
                    }}
                    choices={{
                        [true]: 'Yes',
                        [false]: 'No'
                    }}
                />
            )}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAndStrata)
