import * as Yup from 'yup'
import DeclineMessages from '../../components/forms/base/DeclineMessages'
const schema = Yup.object().shape({
    address: Yup.object()
        .shape({
            formatted: Yup.string().required('Required'),
            isManualAddress: Yup.boolean(),
            streetAddress: Yup.string().required('Required'),
            suiteAddress: Yup.string().nullable(),
            // additionalAddress: Yup.string().nullable(),
            // town: Yup.string().nullable(),
            city: Yup.string().required('Required'),
            region: Yup.string().required('Required'),
            postcode: Yup.string().required('Required')
            // country: Yup.string().required('Required')
        })
        .required('Required'),
    hasPlatform: Yup.bool()
        .required('Required')
        .oneOf([true], DeclineMessages.DM1),
    propertyType: Yup.string()
        .required('Required')
        .test('checkDM2', DeclineMessages.DM2, async (val) => {
            if (val === 'retirement') return false
            return true
        })
        .test('checkDM3', DeclineMessages.DM3, async (val) => {
            if (val === 'temporary') return false
            return true
        })
        .test('checkDM4', DeclineMessages.DM4, async (val) => {
            if (val === 'caravan') return false
            return true
        })
        .test('checkDM5', DeclineMessages.DM5, async (val) => {
            if (val === 'other') return false
            return true
        }),
    grannyFlatConverted: Yup.string().when('propertyType', {
        is: 'grannyFlat',
        then: Yup.string().required('required')
    }),
    grannyFlatDwelling: Yup.string().when('propertyType', {
        is: 'grannyFlat',
        then: Yup.string().required('required')
    }),
    grannyFlatRelocated: Yup.bool()
        .when('grannyFlatConverted', {
            is: 'built',
            then: Yup.bool().required('required')
        })
        .oneOf([false], DeclineMessages.DM4_2),
    grannyFlatSelfContained: Yup.bool().when('propertyType', {
        is: 'grannyFlat',
        then: Yup.bool().required('required')
    }),
    tinyHomeSelfContained: Yup.bool().when('propertyType', {
        is: 'tinyHome',
        then: Yup.bool().required('required')
    }),
    tinyHomeRelocated: Yup.bool()
        .when('propertyType', {
            is: 'tinyHome',
            then: Yup.bool().required('required')
        })
        .oneOf([false], DeclineMessages.DM4_2),
    cabinRelocated: Yup.bool()
        .when('propertyType', {
            is: 'cabin',
            then: Yup.bool().required('required')
        })
        .oneOf([false], DeclineMessages.DM4_2),
    cabinSelfContained: Yup.bool().when('propertyType', {
        is: 'cabin',
        then: Yup.bool().required('required')
    }),
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
    // time: Yup.string().required('Required'),
    // policy: Yup.bool().required('Required'),
    atProperty: Yup.bool().required('Required'),
    numberOfGuests: Yup.number(),
    maxNumberOfGuests: Yup.string(),
    building: Yup.number().required('Required'),
    contents: Yup.number().required('Required'),
    excess: Yup.number().required('Required'),
    purchase: Yup.bool().required('Required'),
    wellMaintained: Yup.bool()
        .required('Required')
        .oneOf([true], DeclineMessages.DM6)
})

export default schema
