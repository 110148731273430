import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import { vehicleLookup } from '../../../actions/vehicle'

const getModels = ({ year, make }) =>
    vehicleLookup({
        lookup: 'model',
        year,
        make
    })

const VehicleModel = (props) => {
    const [models, setModels] = useState([])
    const [field] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    useEffect(() => {
        if (values.year && values.make) {
            getModels(values).then((data) => {
                setModels(data)

                // reset value as its not in our new set
                if (!data.includes(values[field.name])) {
                    setFieldValue(field.name, '')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.year, values.make])

    if (!values.make) {
        return null
    }

    const choices = Object.fromEntries(models.map((m) => [m, m]))

    return <Select {...field} {...props} choices={choices} />
}

VehicleModel.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string
}

VehicleModel.defaultProps = {
    name: 'model',
    label: 'Model',
    required: true,
    placeholder: 'Please select a model...'
}

export default VehicleModel
