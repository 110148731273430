import * as actions from '../actions'

const INITIAL_STATE = {
    loading: false,
    initialHomecover: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.GET_POLICY_REQUEST:
            return { ...INITIAL_STATE, loading: true }

        case actions.GET_POLICY_SUCCESS:
            return { ...INITIAL_STATE, initialHomecover: action.item }

        case actions.GET_POLICY_FAILED:
            return { ...INITIAL_STATE }

        default:
            return state
    }
}
