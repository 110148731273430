import React from 'react'
import { connect } from 'react-redux'
import { Select, Loading } from '@sharecover-co/ui'
import { useFormikContext } from 'formik'
import get from 'lodash.get'
import { addFacts } from '../../../actions/session'
import { quoteSuccess } from '../../../actions/quote'
import TotalModal from './../../modals/TotalModal'
import initialValues from '../initialValues'
const InfoSelector = (props) => {
    const formik = useFormikContext()
    let prefillList = {}
    if (get(props, 'initialHomeCoverValues', false)) {
        props.initialHomeCoverValues?.initialHomecover?.forEach((value) => {
            prefillList[`${value.address.formatted}`] = value.address.formatted
        })
    }
    const getLatestPolicy = (list) => {
        let max = list[0]
        for (let i = 1; i < list.length; i++) {
            const element = list[i]
            if (
                new Date(element.createdAt).getTime() >
                new Date(max.createdAt).getTime()
            ) {
                max = element
            }
        }
        return max
    }
    const onPrefillChange = (value) => {
        props.addFacts(props.initialValues || {})
        let filtredData = props.initialHomeCoverValues.initialHomecover.filter(
            (item) => item.address.formatted === value
        )
        let max = getLatestPolicy(filtredData)
        if (!max) {
            props.setFormikInitialValue({
                ...initialValues,
                prefiller: 'New address'
            })
            props.addFacts({ ...initialValues, prefiller: 'New address' })
        } else {
            max.prefiller = value
            max.address.suiteAddress = max.address.suiteAddress || ''
            max.address.additionalAddress = max.address.additionalAddress || ''
            max.address.town = max.address.town || ''
            delete max.createdAt
            props.setFormikInitialValue(props.initialValues)
            setTimeout(() => {
                props.setFormikInitialValue(max)
            }, 100)
            props.addFacts(max)
            props.quoteSuccess({ data: null })
        }
    }
    return (
        <>
            {props.initialHomeCoverValues.loading && <Loading />}
            {props.initialHomeCoverValues.initialHomecover &&
                Object.keys(prefillList).length !== 0 && (
                    <Select
                        name="prefiller"
                        id="prefiller"
                        label="Please select"
                        choices={{ newAddress: 'New address', ...prefillList }}
                        onChange={(e) => {
                            formik.handleChange(e)
                            onPrefillChange(e.target.value)
                        }}
                    />
                )}
            {props.initialHomeCoverValues.initialHomecover &&
                Object.keys(prefillList).length === 0 && (
                    <>
                        <TotalModal
                            question="Why isn’t my address displayed?"
                            content={`Addresses you enter will now be stored in a way that you can use them when getting quotes in the future.`}
                        />
                    </>
                )}
        </>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    quote: get(state, 'quote.quote', null),
    initialHomeCoverValues: get(state, 'initialHomeCoverValues', null)
})

const mapDispatchToProps = {
    addFacts,
    quoteSuccess
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoSelector)
