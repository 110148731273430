import { connect } from 'react-redux'
import { Radio } from '@sharecover-co/ui'
import TotalModal from './../../modals/TotalModal'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
import { useFormikContext } from 'formik'
const WellMaintainedAndBooking = (props) => {
    const formik = useFormikContext()
    let condition =
        get(formik, 'values.address.formatted', null) &&
        get(formik, 'values.isStrata', null) &&
        get(formik, 'values.hasPlatform', null) === 'true' &&
        get(formik, 'values.propertyType', null) &&
        get(formik, 'values.propertyType', null) !== 'other' &&
        get(formik, 'values.propertyType', null) !== 'caravan' &&
        get(formik, 'values.propertyType', null) !== 'temporary' &&
        get(formik, 'values.propertyType', null) !== 'retirement'
    return condition ? (
        <>
            <div
                style={{
                    width: '101%'
                }}
            >
                <Radio
                    name="wellMaintained"
                    id="wellMaintained"
                    label="Is the building structurally sound and well maintained?"
                    required
                    choices={{
                        [true]: 'Yes',
                        [false]: 'No'
                    }}
                />
            </div>
            <TotalModal
                question="What does this mean?"
                content="Structurally sound (the foundations, load bearing walls and roof are solid)
Well-maintained (it is kept in good condition, for example gutters cleared and not rusted, woodwork painted and gardens well-kept)."
            />
            {props.data.wellMaintained &&
                props.data.wellMaintained === 'true' &&
                props.product.id !==
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <Radio
                        name="policy"
                        id="policy"
                        label="Is this policy for more than one booking?"
                        required
                        choices={{
                            [true]: 'Yes',
                            [false]: 'No'
                        }}
                    />
                )}
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    product: get(state, 'product.product', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WellMaintainedAndBooking)
