import React from 'react'
import { useModal } from 'react-modal-hook'
import { Heading, Text, Button, Modal, Link } from '@sharecover-co/ui'
const shareCoverUrl = `${process.env.REACT_APP_MARKETING_URL}`
export default ({ title = '', content = '', question = '' }) => {
    const [showModal, hideModal] = useModal(() => (
        <Modal onRequestClose={hideModal}>
            <Heading as="h4">{title}</Heading>
            <Text>{content}</Text>

            <Link href={`${shareCoverUrl}/more-cover/`} target="_blank">
                Need a different amount of building or contents cover?
            </Link>
            <br />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly'
                }}
            >
                <Button sx={{ mt: 3 }} onClick={hideModal}>
                    OK
                </Button>
            </div>
        </Modal>
    ))

    return (
        <Button variant="text" type="button" onClick={showModal}>
            {question}
        </Button>
    )
}
