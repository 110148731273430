// Auth
export const AUTHED = 'AUTHED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const LOGOUT = 'LOGOUT'

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED'

export const CONFIRM_ACCOUNT_REQUEST = 'CONFIRM_ACCOUNT_REQUEST'
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS'
export const CONFIRM_ACCOUNT_FAILED = 'CONFIRM_ACCOUNT_FAILED'

export const FEDERATED_SIGNIN_REQUEST = 'FEDERATED_SIGNIN_REQUEST'
export const FEDERATED_SIGNIN_SUCCESS = 'FEDERATED_SIGNIN_SUCCESS'
export const FEDERATED_SIGNIN_FAILED = 'FEDERATED_SIGNIN_FAILED'

// Session
export const NEW_SESSION = 'NEW_SESSION'
export const ADD_FACTS = 'ADD_FACTS'

// Product
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED'
// Policy
export const GET_POLICY_REQUEST = 'GET_POLICY_REQUEST'
export const GET_POLICY_SUCCESS = 'GET_POLICY_SUCCESS'
export const GET_POLICY_FAILED = 'GET_POLICY_FAILED'

// Quote
export const QUOTE_REQUEST = 'QUOTE_REQUEST'
export const QUOTE_SUCCESS = 'QUOTE_SUCCESS'
export const QUOTE_FAILED = 'QUOTE_FAILED'
export const QUOTE_RREMOVE = 'QUOTE_RREMOVE'
