import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Text, Alert, Select, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'
import VehicleMake from '../../forms/base/VehicleMake'
import VehicleModel from '../../forms/base/VehicleModel'
import VehicleVariant from '../../forms/base/VehicleVariant'
import VehicleSeries from '../../forms/base/VehicleSeries'
import VehicleStyle from '../../forms/base/VehicleStyle'
import { Link } from '@sharecover-co/ui'
import { vehicleLookup } from '../../../actions/vehicle'

const schema = Yup.object().shape({
    year: Yup.string().required('Required'),
    make: Yup.string().required('Required'),
    model: Yup.string().required('Required'),
    variant: Yup.string().required('Required'),
    series: Yup.string().required('Required'),
    style: Yup.string().required('Required')
})

const getYearList = () =>
    vehicleLookup({
        lookup: 'year'
    })

const Vehicle = ({ next, addFacts }) => {
    const [yearList, setYearList] = useState([])

    useEffect(() => {
        getYearList()
            .then((res) => {
                setYearList(res)
            })
            .catch((e) => console.error(e))
    }, [])

    const history = useHistory()

    const yearChoices = Object.fromEntries(yearList.map((m) => [m, m]))

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Vehicle Selection</Heading>
            <Text as="p">
                The first step is to identify your vehicle by completing the
                vehicle lookup. We only insure those vehicles that are available
                in the list.
            </Text>
            <FormManager
                useSession
                initialValues={{
                    year: '',
                    make: '',
                    model: '',
                    variant: '',
                    series: '',
                    style: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Select
                            name="year"
                            label="Year"
                            placeholder="Please select a year..."
                            required
                            choices={yearChoices}
                        />
                        <VehicleMake />
                        <VehicleModel />
                        <VehicleVariant />
                        <VehicleSeries />
                        <VehicleStyle />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Vehicle
