import { connect } from 'react-redux'
import { Radio } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const TinyHome = (props) => {
    return (
        <>
            <Radio
                name="tinyHomeSelfContained"
                id="tinyHomeSelfContained"
                label="Is the Tiny Home self contained, with it's own kitchen, toilet, and bathroom?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            <Radio
                name="tinyHomeRelocated"
                id="tinyHomeRelocated"
                label="Is the Tiny Home designed to be relocated, and constructed on chassis with wheels?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(TinyHome)
