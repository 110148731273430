import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Select, Text, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    claims: Yup.string()
        .required('Required')
        .oneOf(
            ['0', '1', '2', '3'],
            "Based on the information you have provided, we regret that we cannot offer you a quote for insurance at this time because of the number of at-fault claims and/or the value of your vehicle and/or you do not have enough driving experience. You can ask us for the information we relied on in assessing your application for insurance by going to www.sharecover.com/contact. For information on your options of alternative insurance you can access the Insurance Council of Australia's 'Find an Insurer' service at www.findaninsurer.com.au or the National Insurance Brokers Association (NIBA) at www.needabroker.com.au. If you are unhappy with our decision, you can access our complaints process by going to www.sharecover.com/complaints."
        )
})

const Claims = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Claims History</Heading>
            <FormManager
                useSession
                initialValues={{
                    claims: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Select
                            name="claims"
                            label="At-fault motor claims made in the last 3 years"
                            placeholder="How many at-fault motor claims have you made in the last 3 years?"
                            required
                            choices={{
                                0: 'None',
                                1: '1',
                                2: '2',
                                3: '3',
                                over3: 'More than 3'
                            }}
                        />
                        <Text as="p">
                            Include the number of claims in the past 3 years
                            where we or any other insurer were not able to
                            recover the cost of the damage, for example fire
                            damage, at fault collision with another vehicle, and
                            damage whilst parked.
                        </Text>
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Claims
