import { API, graphqlOperation } from 'aws-amplify'
import * as actions from './index'
import * as queries from '../graphql/queries'

// getProduct
const getProductRequest = (id) => ({ type: actions.GET_PRODUCT_REQUEST, id })
const getProductSuccess = (item) => ({
    type: actions.GET_PRODUCT_SUCCESS,
    item
})
const getProductFailed = (error) => ({
    type: actions.GET_PRODUCT_FAILED,
    error
})

export const getProduct = (id) => async (dispatch) => {
    dispatch(getProductRequest(id))

    try {
        const response = await API.graphql(
            graphqlOperation(queries.getProduct, { id })
        )

        if (!response.data.getProduct) {
            throw new Error('No product found.')
        }

        dispatch(getProductSuccess(response.data.getProduct))
    } catch (e) {
        dispatch(getProductFailed(e.message))
        throw e
    }
}
