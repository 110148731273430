import React from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'formik'
import * as Yup from 'yup'
import { Heading, Alert, Checkbox, Submit } from '@sharecover-co/ui'
import FormManager from '../../forms/FormManager'

const schema = Yup.object().shape({
    platforms: Yup.array().required('Should NOT have fewer than 1 items')
})

const Platforms = ({ next, addFacts }) => {
    const history = useHistory()

    return (
        <>
            <Heading sx={{ fontSize: 2 }}>Rideshare Platforms</Heading>
            <FormManager
                useSession
                initialValues={{
                    platforms: []
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    await addFacts(values)
                    history.push(next)
                }}
            >
                {({ status: { error } }) => (
                    <Form>
                        {error && <Alert>{error}</Alert>}
                        <Checkbox
                            name="platforms"
                            label="Please select all platforms that you use for rideshare driving."
                            choices={{
                                didi: 'DiDi',
                                GoCatch: 'GoCatch',
                                inDrive: 'inDrive',
                                ola: 'Ola',
                                shebah: 'Shebah',
                                uber: 'Uber'
                            }}
                            required
                        />
                        <Submit label="Next" />
                    </Form>
                )}
            </FormManager>
        </>
    )
}

export default Platforms
