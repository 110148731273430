import React, { useEffect, useState } from 'react'

import { Text } from '@sharecover-co/ui'

import { useFormikContext } from 'formik'

import { DateTime } from 'luxon'
import { daysDiff } from '../../../utils/dates'
//import getPeriodSuggestion from './../base/PeriodSuggestion'
//import { getBundleType } from '../../../utils/bundles'
//const shareCoverUrl = `${process.env.REACT_APP_MARKETING_URL}`
const dayDiffForSelect = (values) => {
    const diff = daysDiff(values.startDate, values.endDate)
    return diff && [7, 30, 90].indexOf(diff) !== -1 ? diff : 0
}

const startDateHandler = (values, setValues) => {
    if (!values.startDate || values.startDate === '') {
        setValues({
            ...values,
            startDate: '',
            days: 0,
            endDate: ''
        })
    } else if (values.endDate && values.endDate !== '') {
        setValues({
            ...values,
            days: dayDiffForSelect(values)
        })
    }
}

const daysHandler = (values, setValues) => {
    if (values.days && values.days > 0) {
        const newEndDate = DateTime.fromISO(values.startDate)
            .plus({ days: values.days })
            .toISODate()
        setValues({
            ...values,
            endDate: newEndDate
        })
    }
}

const endDateHandler = (values, setValues) => {
    if (!values.endDate || values.endDate === '') {
        setValues({
            ...values,
            days: 0
        })
    } else if (values.startDate && values.startDate !== '') {
        setValues({
            ...values,
            days: dayDiffForSelect(values)
        })
    }
}

const fieldChanged = (currentValues, values, setValues) => {
    if (currentValues !== undefined) {
        if (currentValues.startDate !== values.startDate) {
            startDateHandler(values, setValues)
        } else if (currentValues.days !== values.days) {
            daysHandler(values, setValues)
        } else if (currentValues.endDate !== values.endDate) {
            endDateHandler(values, setValues)
        }
    }
}

const ChangeHandler = () => {
    const [currentValues, setCurrentValues] = useState(undefined)
    const { values, setValues } = useFormikContext()
    useEffect(() => {
        fieldChanged(currentValues, values, setValues)
        setCurrentValues(values)
    }, [currentValues, setCurrentValues, values, setValues])
    return null
}

const getBundleOffer = (noOfNights) => {
    //  const bundleType = getBundleType(noOfNights)
    //  const upgradeText = getPeriodSuggestion(noOfNights)

    return (
        <>
            {/* this part is for bundle */}
            {/* {upgradeText && (
                <Text as="p" sx={{ mb: 0 }}>
                    <small>{upgradeText}</small>
                    {noOfNights > 6 && (
                        <>
                            <br />
                            <br />
                        </>
                    )}
                </Text>
            )} */}
            {/* {bundleType && (
                <>
                    <Text as="p">
                        <span>
                            The following April Bundle will be applied *:
                        </span>
                    </Text>
                    <Bundle bundleType={bundleType} />
                    <br />
                    <Text sx={{ fontSize: 10 }}>
                        * ShareCover April Bundles Offer - Terms and Conditions
                        <br />
                        This offer only applies to new ShareCover Pay-per-night
                        Short-stay Insurance policies taken out between 1 April
                        2022 and 28 April 2022 with an Australian risk address
                        only, and a policy start date of no later than 1 May
                        2022. The number of nights applicable to each bundle
                        must be taken out consecutively from the policy start
                        date. The free nights for the relevant bundle will still
                        apply when you purchase a greater number of nights than
                        ‘The Long Weekender’, ‘The School Holidays’, or ‘The
                        Monthly’ Bundle. The Bundle period can include multiple
                        guest bookings. This offer cannot be used in conjunction
                        with any other promotional offer or other specials. This
                        offer may be withdrawn at any time. For the full terms
                        and conditions, click{' '}
                        <Link
                            href={`${shareCoverUrl}/april-bundles-terms-and-conditions`}
                            target="_blank"
                        >
                            here
                        </Link>{' '}
                        .{' '}
                    </Text>
                </>
            )} */}
        </>
    )
}

const NumberOfNights = () => {
    const [noOfNights, setNoOfNights] = useState(undefined)
    const { values } = useFormikContext()
    useEffect(() => {
        setNoOfNights(daysDiff(values.startDate, values.endDate))
    }, [values, setNoOfNights])

    if (noOfNights !== undefined) {
        return (
            <>
                <Text as="p">
                    Total No. of Nights: <strong>{noOfNights}</strong>
                </Text>
                {getBundleOffer(noOfNights)}
            </>
        )
    }
    return null
}

export { ChangeHandler, NumberOfNights }
