import React from 'react'
import { Box, Link } from '@sharecover-co/ui'

export default () => (
    <Box variant="fixedResponsive" sx={{ px: 4, py: 2 }}>
        <h3>Sorry, page not found!</h3>
        <Link
            href={process.env.REACT_APP_MARKETING_URL}
            sx={{
                variant: 'links.button',
                px: 5,
                py: 2,
                my: [1, 0],
                minWidth: 200
            }}
        >
            Back to home
        </Link>
    </Box>
)
