const isToday = (dte) => {
    const today = new Date()
    const givenDate = new Date(dte)
    return (
        givenDate.getDate() === today.getDate() &&
        givenDate.getMonth() === today.getMonth() &&
        givenDate.getFullYear() === today.getFullYear()
    )
}

export default isToday
