const initialValues = {
    //address
    address: {
        formatted: true,
        isManualAddress: true,
        suiteAddress: true,
        streetAddress: true,
        town: true,
        city: true,
        region: true,
        postcode: true,
        country: true,
        placeId: true,
        lat: true,
        lng: true,
        additionalAddress: true
    },
    prefiller: true,
    //platform
    hasPlatform: true,
    //3
    isStrata: true,
    //4
    propertyType: true,
    //5
    //a
    grannyFlatDwelling: true,
    //b
    grannyFlatConverted: true,
    //c
    grannyFlatRelocated: true,
    //d
    grannyFlatSelfContained: true,
    //6
    //a
    tinyHomeSelfContained: true,
    //b
    tinyHomeRelocated: true,
    //7
    //a
    cabinRelocated: true,
    //b
    cabinSelfContained: true,
    //8
    policy: true,
    //9-12
    startDate: true,
    days: true,
    months: true,
    endDate: true,
    time: true,
    //14
    //a
    numberOfGuests: true,
    atProperty: true,
    //15 16 17
    building: true,
    contents: true,
    excess: true,
    //18 19
    purchase: true,
    wellMaintained: true,
    // details
    notFitProperty: true,
    structurallySound: true,
    propertyRelevance: true,
    noRentDefault: true
}

export default initialValues
