import moment from 'moment'
let monthToDays = {
    6: 183,
    12: 365
}
function addMonths(date, months) {
    date.setDate(date.getDate() + +monthToDays[months])
    return date
}
export default async (e, props, formik) => {
    const [year, month, day] = props.data.startDate.split('-')
    const [endDay, endMonth, endYear] = moment(
        addMonths(new Date(+year, +month - 1, +day), +e.target.value)
    )
        .format('DD/MM/YYYY')
        .split('/')
    const endDate = `${endYear}-${endMonth}-${endDay}`
    formik.setFieldValue('endDate', +e.target.value ? endDate : '')
    formik.handleChange(e)
}
