import { connect } from 'react-redux'
import { Radio, Select } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const GrannyFlat = (props) => {
    return (
        <>
            <Select
                name="grannyFlatDwelling"
                id="grannyFlatDwelling"
                label="Is the Granny Flat attached to the main dwelling on the property?"
                required
                choices={{
                    main: 'Within the main dwelling',
                    semiDetached: 'Semi Detached Dwelling',
                    freeStanding: 'Free Standing Structure'
                }}
            />
            <Select
                name="grannyFlatConverted"
                id="grannyFlatConverted"
                label="Was The Granny Flat an exisiting structure that was converted to a Granny Flat?"
                required
                choices={{
                    convertedGarage: 'Yes, converted garage',
                    convertedShed:
                        'Yes, converted shed, barn, or other workspace',
                    built: 'No, it was built as a granny flat'
                }}
            />
            {props.data.grannyFlatConverted &&
                props.data.grannyFlatConverted === 'built' && (
                    <Radio
                        name="grannyFlatRelocated"
                        id="grannyFlatRelocated"
                        label="Is the Granny Flat designed to be relocated, and constructed on chassis with wheels?"
                        required
                        choices={{
                            [true]: 'Yes',
                            [false]: 'No'
                        }}
                    />
                )}
            <Radio
                name="grannyFlatSelfContained"
                id="grannyFlatSelfContained"
                label="Is the Granny Flat self contained, with it's own kitchen, toilet, and bathroom?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(GrannyFlat)
