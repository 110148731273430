import { API, graphqlOperation } from 'aws-amplify'
import * as actions from './index'
import * as queries from '../graphql/queries'
import initialValues from '../components/forms/initialValues'
import { get } from 'lodash'
import { Auth } from 'aws-amplify'

const getInitialHomeCoverRequest = () => ({
    type: actions.GET_POLICY_REQUEST
})
const getInitialHomeCoverSuccess = (item) => ({
    type: actions.GET_POLICY_SUCCESS,
    item
})
const getInitialHomeCoverFailed = (error) => ({
    type: actions.GET_POLICY_FAILED,
    error
})

export const getInitialHomeCover = () => async (dispatch, getState) => {
    let initialData = {}
    let state = getState()
    dispatch(getInitialHomeCoverRequest())
    try {
        const authenticationReq = await Auth.currentCredentials()
        const isAuthenticated = authenticationReq.authenticated
        if (isAuthenticated) {
            const response = await API.graphql(
                graphqlOperation(queries.listPolicies)
            )
            if (!response.data.listPolicies) {
                initialData = initialValues
            } else {
                const hom = getHomPolicy(
                    response.data.listPolicies,
                    state.session.productId
                )
                initialData = setInitialValue(hom)
            }
        } else {
            initialData = null
        }

        dispatch(getInitialHomeCoverSuccess(initialData))
    } catch (e) {
        dispatch(getInitialHomeCoverFailed(e.message))
        throw e
    }
}

const getHomPolicy = (list, productId) => {
    let productType = ''
    if (productId === process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID)
        productType = 'LHOM'
    else productType = 'HOM'
    return list.filter((item) => item.id.startsWith(productType))
}
const setInitialValue = (list) => {
    const listInitialData = []
    list.forEach((element) => {
        if (
            new Date(element.createdAt).getTime() >
            new Date('2022-09-24T00:00:00.940Z').getTime()
        ) {
            let value = JSON.parse(element.data)
            const data = {
                createdAt: element.createdAt,
                address: { ...value.address, country: 'AU' },
                hasPlatform: `${value.hasPlatform}`,
                isStrata: `${value.isStrata}`,
                propertyType: value.propertyType,
                grannyFlatDwelling: `${get(value, 'grannyFlatDwelling', '')}`,
                grannyFlatConverted: `${get(value, 'grannyFlatConverted', '')}`,
                grannyFlatRelocated: `${get(value, 'grannyFlatRelocated', '')}`,
                grannyFlatSelfContained: `${get(
                    value,
                    'grannyFlatSelfContained',
                    ''
                )}`,
                tinyHomeSelfContained: `${get(
                    value,
                    'tinyHomeSelfContained',
                    ''
                )}`,
                tinyHomeRelocated: `${get(value, 'tinyHomeRelocated', '')}`,
                cabinRelocated: `${get(value, 'cabinRelocated', '')}`,
                cabinSelfContained: `${get(value, 'cabinSelfContained', '')}`,
                months: 0,
                policy: '',
                startDate: '',
                days: 0,
                endDate: '',
                time: '',
                numberOfGuests: '',
                atProperty: '',
                building: value.building,
                contents: value.contents,
                excess: value.excess,
                purchase: '',
                wellMaintained: '',
                notFitProperty: '',
                structurallySound: '',
                propertyRelevance: '',
                noRentDefault: ''
            }
            listInitialData.push(data)
        }
    })
    return listInitialData
}
