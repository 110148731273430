import { connect } from 'react-redux'
import { Radio, Select, Text } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const NumberOfGustsAndAtProperty = (props) => {
    let condition =
        get(props, 'data.address.formatted', null) &&
        get(props, 'data.isStrata', null) &&
        get(props, 'data.hasPlatform', null) === 'true' &&
        get(props, 'data.propertyType', null) &&
        get(props, 'data.propertyType', null) !== 'other' &&
        get(props, 'data.propertyType', null) !== 'caravan' &&
        get(props, 'data.propertyType', null) !== 'temporary' &&
        get(props, 'data.propertyType', null) !== 'retirement' &&
        (get(props, 'data.policy', null) ||
            (get(props, 'data.wellMaintained', null) &&
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID)) &&
        get(props, 'data.endDate', null)

    return condition ? (
        <>
            {props.data.policy === 'false' &&
                props.product.id !==
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                    <>
                        <Select
                            id="numberOfGuests"
                            name="numberOfGuests"
                            label="Number of Guests"
                            required
                            choices={Object.fromEntries(
                                [...Array(15).keys()].map((i) => [++i, i])
                            )}
                        />
                    </>
                )}
            {props.product.id ===
                process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID && (
                <Text>
                    Please let us know the maximum number of guests you can
                    accommodate.
                </Text>
            )}
            {(props.data.policy === 'true' ||
                props.product.id ===
                    process.env.REACT_APP_LONG_HOMECOVER_PRODUCT_ID) && (
                <>
                    <Select
                        id="numberOfGuests"
                        name="numberOfGuests"
                        label="Max. Number of Guests"
                        required
                        choices={Object.fromEntries(
                            [...Array(15).keys()].map((i) => [++i, i])
                        )}
                    />
                </>
            )}
            <Radio
                name="atProperty"
                id="atProperty"
                label="Will you generally be at the property during guests stays?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
        </>
    ) : (
        <></>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {}),
    product: get(state, 'product.product', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NumberOfGustsAndAtProperty)
