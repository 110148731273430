const formatCurrency = (n) =>
    new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0
    }).format(n)

const getMin = (isStrata) => {
    if (isStrata === 'true') {
        return 60000
    } else {
        return 150000
    }
}
const getMax = (isStrata) => {
    if (isStrata === 'true') {
        return 500000
    } else {
        return 2000000
    }
}

const buildingStep = 50000
const getBuildingMarks = (isStrata) => {
    const buildingMarks = [{ value: getMax(isStrata) }]
    let currentValue = getMax(isStrata)
    let counter = 0
    while (currentValue - buildingStep > getMin(isStrata) || counter > 100) {
        currentValue = currentValue - buildingStep
        buildingMarks.push({
            value: currentValue
        })
        counter++ // failsafe break
    }
    buildingMarks.push({ value: getMin(isStrata) })
    return buildingMarks.reverse()
}

export { getMin, getMax, getBuildingMarks, formatCurrency }
