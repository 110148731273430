const initialValues = {
    //address
    address: {
        formatted: '',
        isManualAddress: true,
        suiteAddress: '',
        streetAddress: '',
        town: '',
        city: '',
        region: '',
        postcode: '',
        country: 'AU',
        placeId: '',
        lat: '',
        lng: '',
        additionalAddress: ''
    },
    prefiller: '',
    //platform
    hasPlatform: '',
    //3
    isStrata: '',
    //4
    propertyType: '',
    //5
    //a
    grannyFlatDwelling: '',
    //b
    grannyFlatConverted: '',
    //c
    grannyFlatRelocated: '',
    //d
    grannyFlatSelfContained: '',
    //6
    //a
    tinyHomeSelfContained: '',
    //b
    tinyHomeRelocated: '',
    //7
    //a
    cabinRelocated: '',
    //b
    cabinSelfContained: '',
    //8
    policy: '',
    //9-12
    startDate: '',
    days: 0,
    months: 0,
    endDate: '',
    time: '',
    //14
    //a
    numberOfGuests: 1,
    atProperty: '',
    //15 16 17
    building: 60000,
    contents: 30000,
    excess: 100,
    //18 19
    purchase: '',
    wellMaintained: '',
    // details
    notFitProperty: '',
    structurallySound: '',
    propertyRelevance: '',
    noRentDefault: ''
}

export default initialValues
