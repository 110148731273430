import { connect } from 'react-redux'
import { Radio } from '@sharecover-co/ui'
import { addFacts } from '../../../actions/session'
import get from 'lodash.get'
const Cabin = (props) => {
    return (
        <>
            <Radio
                name="cabinRelocated"
                id="cabinRelocated"
                label="Is the Cabin designed to be relocated, and constructed on chassis with wheels?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
            <Radio
                name="cabinSelfContained"
                id="cabinSelfContained"
                label="Is the Cabin self contained, with it's own kitchen, toilet, and bathroom?"
                required
                choices={{
                    [true]: 'Yes',
                    [false]: 'No'
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    data: get(state, 'session.data', {})
})

const mapDispatchToProps = {
    addFacts
}

export default connect(mapStateToProps, mapDispatchToProps)(Cabin)
